import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            padding: 30,
        },
        title: {
            textAlign: "center",
        },
        logo: {
            position: "absolute",
            top: 15,
            left: 15,
        },
        progress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        error: {
            color: theme.palette.error.main,
        },
        signUpOr: {
            position: "relative",
            top: "-15px",
            backgroundColor: theme.palette.primary.contrastText,
            display: "inline-block",
            width: "40px",
            textAlign: "center",
            left: "50%",
            marginLeft: "-20px",
            color: "grey",
        },
        signUp: {
            textAlign: "center",
            cursor: "pointer",
        },
        loginButton: {
            marginBottom: 10,
        },
        signInGoogleButton: {
            textAlign: "center",
            margin: 10,
        },
        fpEmailField: {
            width: 300,
            marginRight: 5,
        },
        fpResetButton: {
            height: 56,
        },
    })
);
