import React from "react";
import { Grid } from "@material-ui/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useStyles } from "./styles";
import { ContactUs, TopBorderContainer } from "$components/core";

type EventInformationDetailsProps = {
    eventId: string;
    preview?: boolean;
};

const EventInformationDetails = (props: EventInformationDetailsProps) => {
    const { eventId, preview } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    return (
        <TopBorderContainer title={getLiteral("event_details.info.contact_us")} className={classes.paper}>
            <Grid container>
                <Grid item xs={12} className={classes.content}>
                    <ContactUs eventId={eventId} preview={preview} />
                </Grid>
            </Grid>            
        </TopBorderContainer>
    );
};

export { EventInformationDetails };
