import React from "react";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { SpinnerButton } from "@djordjeandjelkovic/medgress_common_react_modules";
import { Attendee } from "$components/Attendee";
import AttendeeUpdateLoader from "$components/ContentLoader/AttendeeUpdateLoader";
import { useStyles } from "./styles";
import { useUpdateAttendee } from "./useUpdateAttendee";

export const UpdateAttendee = () => {
    const [{ attendee, isLoading }, { handleChange, handleUpdate }] = useUpdateAttendee();
    const classes = useStyles();

    if (attendee === undefined) {
        return (
            <Container className={classes.root}>
                <Typography variant="h4" className={classes.title}>
                    Edit attendee profile
                </Typography>
                
                <AttendeeUpdateLoader />;
            </Container>
        );
    }

    if (attendee === null) {
        return (
            <Container className={classes.root}>
                <Typography variant="h4" className={classes.title}>
                    No information about attendee.
                </Typography>
            </Container>
        );
    }

    return (
        <div className={classes.root}>
            <Typography variant="h4">
                Edit attendee profile
            </Typography>

            <Attendee
                attendee={attendee}
                onChange={handleChange}
                attendeeType={Number(attendee.attendeeType?.value)}
            />
            
            <SpinnerButton
                isLoading={isLoading!}
                onClick={handleUpdate}
                title="Update"
                ButtonProps={{
                    variant: "contained",
                    color: "primary",
                    className: classes.button,
                    fullWidth: true,
                }}
            />
        </div>
    );
};
