import _forEach from "lodash/forEach";

import { IBuyer } from "$models/Checkout";

import {
    FieldName,
    IAttendee,
    isValidEmail,
    ErrorType,
} from "@djordjeandjelkovic/medgress_common_react_modules";

export const validationBuyer = (buyer: ErrorType<IBuyer>): boolean => {
    let isValid = true;
    if (buyer.name.value === "") {
        buyer.name.error = true;
        isValid = isValid && false;
    } else {
        buyer.name.error = false;
        isValid = isValid && true;
    }

    if (buyer.email.value === "" || !isValidEmail(buyer.email.value)) {
        buyer.email.error = true;
        isValid = isValid && false;
    } else {
        buyer.email.error = false;
        isValid = isValid && true;
    }

    return isValid;
};

export const validationAttendees = (attendee: ErrorType<IAttendee>): boolean => {
    let isValid = true;

    const isValidFirstName = attendee.firstName.value !== "";
    isValid = isValid && isValidFirstName;
    attendee.firstName = { ...attendee.firstName, error: !isValidFirstName };

    const isValidLastName = attendee.lastName.value !== "";
    isValid = isValid && isValidLastName;
    attendee.lastName = { ...attendee.lastName, error: !isValidLastName };

    const isValidEmailAddress = attendee.email.value !== "";
    isValid = isValid && isValidEmailAddress;
    attendee.email = {
        ...attendee.email,
        error: !(isValidEmailAddress && isValidEmail(attendee.email.value)),
    };

    _forEach(attendee.fields, (field, index) => {
        let isValidField = !field.value.required || !(field.value.required && !!!field.value.value);

        if (field.value.type === FieldName.email) {
            isValidField = isValidField && isValidEmail(field.value.value!);
        }

        isValid = isValid && isValidField;
        attendee.fields[index] = { ...field, error: !isValidField };
    });

    return isValid;
};
