import * as api from "$helper/api";
import { EventDTO, EventQRCodeDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

const getList = async (): Promise<EventDTO[] | null> => {
    try {
        return api.get<EventDTO[]>("/events/incoming");
    } catch (error) {
        console.error(error);
        return null;
    }
}

const listWithExternal = async (acronym: string): Promise<EventDTO[]> => {
    return api.get<EventDTO[]>(`/events/incoming/companies/${acronym}/with-external`);
}

const getById = (id: string): Promise<EventDTO> => {
    return api.get<EventDTO>(`/events/${id}`);
}

const getByAcronym = (acronym: string): Promise<EventDTO> => {
    return api.get<EventDTO>(`/events/acronym/${acronym}`);
}

const getQRCode = (eventId: string) => {
    return api.get<EventQRCodeDTO>(`/events/${eventId}/qr`);
}

const eventsService = {
    getList,
    listWithExternal,
    getById,
    getByAcronym,
    getQRCode,
};

export default eventsService;
