import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
        },
        noInformation: {
            textAlign: "center",
            paddingTop: "50pt",
        }
    })
);

