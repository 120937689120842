import { useSnackbar } from "notistack";
import { useCallback } from "react";

export const useMessage = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showInfo = useCallback((message: string) => {
        enqueueSnackbar(message, { variant: "info", });
    }, [enqueueSnackbar]);

    const showSuccess = (message: string) => {
        enqueueSnackbar(message, { variant: "success", });
    }

    const showWarning = (message: string) => {
        enqueueSnackbar(message, { variant: "warning", });
    }

    const showError = useCallback((message: string) => {
        enqueueSnackbar(message, { variant: "error", });
    }, [enqueueSnackbar]);

    return { showInfo, showSuccess, showWarning, showError };
}
