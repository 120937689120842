import { IOption } from "./IOption";

export class Option implements IOption {
    name: string;
    value: string;
    alt?: string;
    disabled?: boolean;

    constructor(name: string, value: string, alt?: string, disabled?: boolean) {
        this.name = name;
        this.value = value;
        this.alt = alt;
        this.disabled = disabled;
    }
}
