import getByAcronym from "./getByAcronym";
import getList from "./getList";
import getPaymentHandler from "./getPaymentHandler";

export { getByAcronym, getList, getPaymentHandler };

export default {
    getByAcronym,
    getList,
    getPaymentHandler,
};
