import { Theme, createStyles, makeStyles } from "@material-ui/core";
import images from "$assets/images";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundImage: `url(${images.popularEvents})`,
            backgroundSize: "cover",
            position: "relative",
            "&:before": {
                content: '""',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                position: "absolute",
            },
        },
        eventsContainer: {
            position: "absolute",
        },
        listItemContainer: {
            zIndex: 1,
            border: `1px solid ${theme.palette.secondary.light}`,
            position: "relative",
        },
        paper: {
            padding: theme.spacing(2),
            margin: "auto",
            maxWidth: 500,
        },
        eventItemImage: {
            height: 246,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
        eventItemDetailsRoot: {
            padding: 15,
        },
        eventItemDetailsContainer: {
            height: 290,
            marginTop: -75,
            padding: 15,
            marginBottom: 10,
            background: theme.palette.background.default,
            borderTop: `5px solid ${theme.palette.secondary.light}`,
        },
        eventItemCategory: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 5,
        },
        eventItemDateContainer: {
            float: "right",
        },
        eventItemDateImage: {
            marginBottom: -6,
        },
        eventItemDate: {
            padding: 2,
            color: theme.palette.text.primary,
        },
        eventItemName: {
            marginTop: 20,
            width: "100%",
            overflow: "hidden",
            height: 64,
            wordBreak: "break-all",
            textOverflow: "ellipsis",
        },
        eventItemDescription: {
            height: 100,
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": 7,
            "-webkit-box-orient": "vertical",
        },
        eventItemLocation: {
            textAlign: "center",
            padding: 17,
            color: theme.palette.text.primary,
        },
        eventItemLocationIcon: {
            verticalAlign: "middle",
            paddingRight: 5,
        },
        loadMoreContainer: {
            textAlign: "center",
        },
        loadMore: {
            marginTop: 30,
            marginBottom: 30,
        },
        noEvents: {
            textAlign: "center",
            paddingTop: 150,
            paddingBottom: 150,
        },
        headerContainer: {
            textAlign: "center",
            padding: 30,
            position: "relative",
        },
        header: {
            marginTop: 40,
            color: theme.palette.primary.contrastText,
        },
        headerEvents: {
            color: theme.palette.primary.main,
        },
        shapeIcon: {
            margin: 10,
        },
        line: {
            marginBottom: 30,
        },
        eventBannerLink: {
            width: "100%",
            height: "100%",
            display: "block",
        },
    })
);
