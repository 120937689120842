import { createStore, compose } from "redux";
import { rootReducer } from "$redux/reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
    }
}

const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ traceLimit: 25, trace: true })) ||
    compose;

const persistConfig = {
    key: "state",
    storage: storage,
    whitelist: ["ticketsCart", "checkout", "literal", "theme", "cartTimeout"],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const createMockStore = (initialValue: any) => createStore(pReducer, initialValue, composeEnhancers());

const store = createStore(pReducer, composeEnhancers());

const persistor = persistStore(store);

export { persistor, store, createMockStore };
