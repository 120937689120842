import React from "react";
import { useEventDetailsState } from "$pages/EventDetails/useEventDetailsState";
import { useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { EventDetailsLoader } from "$components/ContentLoader";
import { Tickets } from "$pages/EventDetails/Tickets";

export const Small = () => {
    const { acronym } = useParams<{ acronym: string }>();
    const { event } = useEventDetailsState({ acronym });

    if (!event) {
        return (
            <Container>
                <EventDetailsLoader />
            </Container>
        );
    }

    return (
        <Container>
            <Tickets acronym={acronym} event={event} embeded />
        </Container>
    );
};
