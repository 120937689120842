import { useCallback } from "react";

import * as PaymentActionCreators from "$redux/actions/Payment/paymentActions";
import { bindActionCreators } from "redux";
import { EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";

import { useDispatch } from "react-redux";

import { usePaymentSelectors } from "$redux/selectors/usePaymentSelectors";

export interface IPaymentState {
    cardType: string;
    cardHolder: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    cvc: string;
    signature: string;
}

export const cardTypes = [
    { name: "VISA", constraints: { cardNumberLength: 16, cvcLength: 3 } },
    { name: "MASTERCARD", constraints: { cardNumberLength: 16, cvcLength: 3 } },
    { name: "AMEX", constraints: { cardNumberLength: 15, cvcLength: 4 } },
    { name: "MADA", constraints: { cardNumberLength: 16, cvcLength: 3 } },
    { name: "MEEZA", constraints: { cardNumberLength: 19, cvcLength: 3 } },
];

const useCreditCardInfoState = (): any[] => {
    const state = usePaymentSelectors();
    const dispatch = useDispatch();
    const { setCardHolder, setCardNumber, setCardType, setCvc, setExpireMonth, setExpireYear, reset } =
        bindActionCreators(PaymentActionCreators, dispatch);

    const handleChange = useCallback(
        (event: EventChange<string>) => {
            const {
                target: { value },
                type,
            } = event;
            switch (event.target.name) {
                case "cardType":
                    setCardType(value);
                    break;
                case "cardHolder":
                    setCardHolder(value);
                    break;
                case "cardNumber":
                    setCardNumber(value);
                    break;
                case "expireMonth":
                    setExpireMonth(type === "blur" ? value.padStart(2, "0") : value);
                    break;
                case "expireYear":
                    setExpireYear(value);
                    break;
                case "cvc":
                    setCvc(value);
                    break;
            }
        },
        [
            setCardType,
            setCardHolder,
            setCardNumber,
            setExpireYear,
            setExpireMonth,
            setCvc,
        ]
    );

    return [
        {
            ...state.payment,
        },
        {
            handleChange,
            reset,
        },
    ];
};

export { useCreditCardInfoState };
