import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.contrastText,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 5,
        },
    })
);
