import React from "react";
import { CardContent, Typography } from "@material-ui/core";
import { useStyles } from "../styles";
import { useLiterals } from "$hooks/Translate/useLiterals";

export const Downloads = () => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    return (
        <>
            <Typography variant="h4" className={classes.title}>
                {getLiteral("user_profile.menu.my_downloads")}
            </Typography>
            <CardContent className={classes.cardContent}>
                <Typography variant="body1">No files for download</Typography>
            </CardContent>
        </>
    );
};
