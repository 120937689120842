import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useAnnouncementsState } from "./useAnnouncementsState";

const Announcements = () => {
    const [{ announcements, classes }, { clearAnnouncements }] = useAnnouncementsState();

    if (announcements === undefined || classes === undefined || announcements.length === 0) {
        return null;
    }    

    return (
        <Grid className={classes.root}>
            <IconButton className={classes.closeButton} onClick={clearAnnouncements}>
                <CloseIcon />
            </IconButton>

            {announcements.map((announcement) => (
                <Typography variant="body1" key={`announcement-${announcement.id}`}>
                    {announcement.description}
                </Typography>
            ))}
        </Grid>
    );
}

export { Announcements };