import React from "react";
import { Grid, Typography, Avatar, Button } from "@material-ui/core";
import { TopBorderContainer } from "$components/core";
import { useStyles } from "../styles";
import { useLiterals } from "$hooks/Translate/useLiterals";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { authenticationService } from "$services/core";
import { useHistory } from "react-router-dom";

import { IUser } from "@djordjeandjelkovic/medgress_common_react_modules";
import { GoogleLogout } from "react-google-login";
import * as config from "$config/config";

type Props = {
    user?: IUser;
    userHasPassword: boolean;
    currentAction: string;
    changeAction: (action: string) => void;
};

export const getInitials = (user: IUser) => {
    return user.firstName[0] + user.lastName[0];
};

export const UserActions = (props: Props) => {
    const classes = useStyles();
    const { user, userHasPassword, currentAction, changeAction } = props;
    const getLiteral = useLiterals();
    const history = useHistory();

    if (!user) {
        return <div>Loading...</div>;
    }

    const getFontWeight = (action: string) => {
        return currentAction === action ? "bold" : "normal";
    }

    const goToPurchases = () => {
        window.location.href= `/purchase-histories/users/${user?.id || ""}`;
    }

    const handleLogout = () => {
        authenticationService.logout();
        history.push("/");
        window.location.reload();
    }

    const handleFailedGoogleLogout = () => {
        console.error("Error while logout from Google");
    }

    let logoutButton = <Button onClick={handleLogout} variant="text" color="primary" className={classes.profileInfoLogout}>{getLiteral("common.button.logout")}</Button>;
    if(authenticationService.isLoggedInByGoogle()) {
        logoutButton = <GoogleLogout
            clientId={config.googleLogin.clientId!}
            buttonText={getLiteral("common.button.logout")}
            onLogoutSuccess={() => handleLogout()}
            onFailure={() => handleFailedGoogleLogout()}
            className={classes.fullWidth}
        />
    }

    return (
        <TopBorderContainer className={classes.paper}>
            <div className={classes.profileInfoContainer}>
                <Grid container className={classes.profileInfo}>
                    <Grid item lg={3} xs={12}>
                        <Avatar className={classes.userPicture} alt="user profile image">
                            {getInitials(user)}
                        </Avatar>
                    </Grid>

                    <Grid item lg={9} xs={12} className={classes.userName}>
                        <Typography variant="h6" className={classes.userInfoValue}>{`${user.firstName} ${user.lastName}`}</Typography>
                        <Typography variant="body1" className={classes.userInfoValue}>{user.email}</Typography>
                        {logoutButton}
                    </Grid>
                </Grid>
            </div>

            <div>
                <ul>
                    <li className={classes.menuItem} style={{ fontWeight: getFontWeight("userDetails")}} onClick={() => changeAction("userDetails")}>
                        {getLiteral("user_profile.menu.user_details")}
                        <ArrowForwardIosIcon className={classes.menuItemArrow} />
                    </li>
                    <li className={classes.menuItem} style={{ fontWeight: getFontWeight(userHasPassword ? "changePassword" : "setPassword")}} onClick={() => changeAction(userHasPassword ? "changePassword" : "setPassword")}>
                        {getLiteral(userHasPassword ? "user_profile.menu.change_password" : "user_profile.menu.set_password")}
                        <ArrowForwardIosIcon className={classes.menuItemArrow} />
                    </li>
                    <li className={classes.menuItem} style={{ fontWeight: getFontWeight("downloads")}} onClick={() => changeAction("downloads")}>
                        {getLiteral("user_profile.menu.my_downloads")}
                        <ArrowForwardIosIcon className={classes.menuItemArrow} />
                    </li>
                    <li className={classes.menuItem} onClick={() => goToPurchases()}>
                        {getLiteral("user_profile.menu.my_purchase")}
                        <ArrowForwardIosIcon className={classes.menuItemArrow} />
                    </li>
                </ul>
            </div>
        </TopBorderContainer>
    );
};
