import { ON_CHANGE, VALIDATION, SET_ATTENDEE_ID, ATTENDEE } from "./attendeeFieldsActionTypes";
import { TicketCart } from "$models/types";

export const onChange = (index: number, eventId: string, name: string, value: string) => ({
    type: ON_CHANGE,
    altType: ATTENDEE,
    index,
    eventId,
    name,
    value,
});

export const setValidationAttendee = (attendees: TicketCart, eventId: string) => ({
    type: VALIDATION,
    altType: ATTENDEE,
    eventId,
    attendees,
});

export const setAttendeeId = (index: number, eventId: string, id: string) => ({
    type: SET_ATTENDEE_ID,
    altType: ATTENDEE,
    index,
    eventId,
    id,
});
