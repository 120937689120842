import React from "react";
import { StepIconProps } from "@material-ui/core";
import { useStyles } from "../styles";
import clsx from "clsx";

export const StepIcon: React.FC<StepIconProps & { stepIcon: React.ComponentType<any> }> = ({
    active,
    completed,
    stepIcon,
}) => {
    const StepIcon = stepIcon;
    const classes = useStyles({ isAttendeeStep: false });

    return (
        <StepIcon className={clsx([{ [classes.activeStep]: active, [classes.completedStep]: completed }])} />
    );
};
