import { usePayFortResponse } from "$hooks/PayFortResponse";
import { useState } from "react";

export const usePaymentResponse = () => {
    const [isSuccess, setSuccess] = useState<boolean | null>(null);
    const payFortResponse = usePayFortResponse();

    const onSuccess = (success: boolean) => {
        setSuccess(success);
    };

    return {
        isSuccess: isSuccess || (payFortResponse.hasResponse ? payFortResponse.isSuccess : null),
        error: isSuccess ? "" : payFortResponse.message,
        onSuccess,
    };
};
