import React, { useState, useEffect, useCallback } from "react";
import eventsService from "$services/events.service";
import { EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { EventFilterDTO } from "$models/EventFilter";
import Typography from "@material-ui/core/Typography";
import EventsList from "$components/EventsList";
import { EventsFilter } from "$components/EventsFilter";
import { PopularEvents, Subscribe, EventItemsContainerLoader } from "$components/core";
import { useStyles } from "./styles";
import { filterEvents } from "../../utilities/eventUtils";

const Events = () => {
    const [list, setList] = useState<EventDTO[]>([]);
    const [filteredList, setFilteredList] = useState<EventDTO[] | undefined>(undefined);
    const [filter, setFilter] = useState<EventFilterDTO>(EventFilterDTO.Default());
    const classes = useStyles();

    useEffect(() => {
        const getEvents = async () => {
            try {
                const events = await eventsService.getList();
                if (events === null) {
                    return;
                }
    
                setList(events);
        
                const filteredEventList = filterEvents(
                    events,
                    EventFilterDTO.Default()
                );
                if (filteredEventList == null) {
                    return;
                }
                setFilteredList(filteredEventList);
            } catch(error) {
                console.error(error);
            }
        }
        
        getEvents();
    }, []);

    const changeEventStartDate = (date: Date) => {
        const eventsFilter = { ...filter };
        eventsFilter.startDate = date;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventType = (category: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.eventType = category;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventName = (name: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.name = name;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventCity = (city: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.city = city;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const changeEventNatureType = (location: string) => {
        const eventsFilter = { ...filter };
        eventsFilter.natureType = location;

        setFilter(eventsFilter);
        applyFilter(list, eventsFilter);
    };

    const applyFilter = useCallback((list: EventDTO[] ,filter: EventFilterDTO) => {
        const filteredEvents = filterEvents(list, filter);
        if (filteredEvents == null) {
            return;
        }
        setFilteredList(filteredEvents);
    }, [setFilteredList]);

    const clearFilter = () => {
        setFilter(EventFilterDTO.Default());
        applyFilter(list, EventFilterDTO.Default());
    };

    if (filteredList === undefined) {
        return (
            <>
                <EventsFilter
                    filter={filter}
                    changeName={changeEventName}
                    changeCity={changeEventCity}
                    changeEventType={changeEventType}
                    changeNatureType={changeEventNatureType}
                    changeStartDate={changeEventStartDate}                    
                    clearFilter={clearFilter}
                />

                <EventItemsContainerLoader />
            </>
        );
    }

    if (list.length === 0) {
        return (
            <>
                <EventsFilter
                    filter={filter}
                    changeName={changeEventName}
                    changeCity={changeEventCity}
                    changeEventType={changeEventType}
                    changeNatureType={changeEventNatureType}
                    changeStartDate={changeEventStartDate}                    
                    clearFilter={clearFilter}
                />

                <Typography variant="h4" className={classes.noUpcomingEvents}>
                    No upcoming events
                </Typography>
            </>
        );
    }

    return (
        <>
            <EventsFilter
                filter={filter}
                changeName={changeEventName}
                changeCity={changeEventCity}
                changeEventType={changeEventType}
                changeNatureType={changeEventNatureType}
                changeStartDate={changeEventStartDate}
                clearFilter={clearFilter}
            />

            <EventsList list={filteredList} />
            <PopularEvents />
            <Subscribe />
        </>
    );
};

export { Events };
