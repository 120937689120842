import * as api from "$helper/api";
import * as config from "../config";
import { ILogin } from "$models/Login";
import { joinAbsoluteUrlPath } from "$helper/api";
import { User, IToken } from "@djordjeandjelkovic/medgress_common_react_modules";

const getToken = (): string | null => {
    return localStorage.getItem("token");
}

const isLoggedIn = (): boolean => {
    const isLoggedIn = localStorage.getItem("token");
    return isLoggedIn !== null;
}

const isLoggedInByGoogle = (): boolean => {
    const isLoggedInByGoogle = localStorage.getItem("google-login");
    return isLoggedInByGoogle !== null && isLoggedInByGoogle === "true";
}

const getUser = (): User | null => {
    const item = localStorage.getItem("user");
    if (item === null) {
        return null;
    }

    return JSON.parse(item);
}

const login = async(email: string, password: string): Promise<void> => {
    const token = await api.post<ILogin, IToken>("/auth/token", {
        email: email,
        password: password,
    });

    parseToken(token);
}

type GoogleToken = {
    token: string;
}

const loginWithGoogle = async(googleToken: string): Promise<void> => {
    const token = await api.post<GoogleToken, IToken>("/auth/google", {
        token: googleToken
    });

    localStorage.setItem("google-login", "true");
    parseToken(token);
}

const parseToken = (token: IToken) => {
    localStorage.setItem("token", token.value);
    localStorage.setItem("refreshtoken", token.refreshToken);

    const payload = JSON.parse(atob(token.value)).payload;
    localStorage.setItem(
        "user",
        JSON.stringify(
            new User(payload.firstName, payload.lastName, payload.email, "", "", undefined, payload.id)
        )
    );
}

const logout = (): void => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshtoken");
    localStorage.removeItem("user");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("google-login");
};

const refreshToken = async(): Promise<void> => {
    const refreshToken = localStorage.getItem("refreshtoken");
    if (refreshToken == null) {
        return;
    }
    try {
        const response = await fetch(joinAbsoluteUrlPath(config.engine.apiUrl!, "/auth/token/renew"), {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: '"' + localStorage.getItem("refreshtoken") + '"',
        });
        const data = await response.json();
        localStorage.setItem("token", data.token.value);
        window.location.reload();
    } catch (error) {
        return console.error(error);
    }
}

export const authenticationService = {
    login,
    loginWithGoogle,
    logout,
    refreshToken,
    isLoggedIn,
    isLoggedInByGoogle,
    getToken,
    getUser,
};
