import React from "react";

import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import { EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";

type TicketCountProps = {
    ticketId: string;
    count: number | undefined;
    name: string;
    disabled?: boolean;
    hidden?: boolean;
    onChange: (event: EventChange<string>, ticketId: string) => void;
};

export const TicketCount = (props: TicketCountProps) => {
    const { count, onChange, ticketId, name, hidden } = props;

    const INCREMENT = 1;
    const DECREMENT = -1;

    const MINIMUM = 0;
    const MAXIMUM = 10;

    const handleClick = (add: number) => () => {
        if (count === MINIMUM && add === DECREMENT) {
            return;
        }

        onChange({ target: { value: String((count || 0) + add), name } }, ticketId);
    };

    return hidden ? null : (
        <>
            <IconButton onClick={handleClick(DECREMENT)} disabled={count === MINIMUM}>
                <RemoveIcon />
            </IconButton>
            <span>{count}</span>
            <IconButton onClick={handleClick(INCREMENT)} disabled={count === MAXIMUM}>
                <AddIcon />
            </IconButton>
        </>
    );
};
