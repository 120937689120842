import React from "react";
import { Popover } from "@material-ui/core";
import { TicketCart } from "./TicketCart";

interface TicketCartPopperProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

const TicketCartPopper = (props: TicketCartPopperProps) => {
    const { open, anchorEl, onClose } = props;

    return (
        <Popover
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}>
            <TicketCart onClose={onClose} />
        </Popover>
    );
};

export { TicketCart, TicketCartPopper };
