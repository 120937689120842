import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";

const ActivateWaiting = () => {
    const classes = useStyles();
    const getLiteral = useLiterals();

    return (
        <div className={classes.waitingWrapper}>
            <Typography className={classes.centerMiddle} variant="h4">
                {getLiteral("activate_account.wait")}
            </Typography>
        </div>
    );
};

export { ActivateWaiting };
