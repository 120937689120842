import { useState, useCallback, useEffect, useMemo } from "react";
import { ticketService } from "$services/core";
import { TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { TicketStatusEnum } from "$enums/TicketStatusEnum";
import { TicketsProps } from "./TicketsProps";
import { useTicketsActionCreators } from "./useTicketsActionCreators";
import { TicketActions, TicketState } from "./TicketReturn";
import { useAddTickets } from "./useAddTickets";
import _groupBy from "lodash/groupBy";
import { Dictionary } from "lodash";
import useTicketsCount from "./useTicketsCount";

export const useTicketsState = (props: TicketsProps): [TicketState, TicketActions] => {
    const { acronym, event } = props;
    const [tickets, setTickets] = useState<TicketDTO[] | undefined>(undefined);
    const { ticketsCount, handleChangeTicketCount, resetTicketsCount } = useTicketsCount(tickets);
    const { initTimeout } = useTicketsActionCreators({ event });
    const { addTicket, addTickets } = useAddTickets({ ticketsCount, event });
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            if (!acronym) {
                return;
            }

            const ticketsList = await ticketService.getTickets(acronym);
            setTickets(ticketsList);
        };
        fetch();
    }, [acronym]);

    const ticketsGrouped: Dictionary<TicketDTO[]> = useMemo(() => {
        return _groupBy(tickets, (x) => x.groupId);
    }, [tickets]);

    const isHiddenTicketsCount = useCallback((ticket: TicketDTO) => {
        return ticket.status === TicketStatusEnum.NO_AVAILABLE || ticket.status === TicketStatusEnum.SOLD_OUT;
    }, []);

    const addTicketsToCart = async (id?: string) => {
        if (!tickets) {
            return;
        }

        initTimeout(event.id);

        try {
            setLoading(true);

            if (id) {
                await addTicket(id);
                handleChangeTicketCount({ target: { value: String(0), name: id } }, id);
            } else {
                await addTickets();
                resetTicketsCount();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    return [
        { tickets: ticketsGrouped, ticketsCount, isLoading },
        {
            handleChangeTicketCount,
            addTicketsToCart,
            isHiddenTicketsCount,
        },
    ];
};
