import React from "react";

import { useStyles } from "./styles";

import { NavLink } from "react-router-dom";

import { MenuItem } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { useLiterals } from "$hooks/Translate/useLiterals";

interface IHistoryPurchaseMenuItemProps {
    onClick?: () => void;
}
const HistoryPurchaseMenuItem: React.FC<IHistoryPurchaseMenuItemProps> = ({ onClick }) => {
    const classes = useStyles({});
    const getLiteral = useLiterals();

    return (
        <MenuItem component={NavLink} to={`/purchase-histories`} onClick={onClick}>
            <ShoppingCartIcon className={classes.icon} />
            {getLiteral("nav_bar.user_profile.purchase_history.menu_item")}
        </MenuItem>
    );
};

export { HistoryPurchaseMenuItem };
