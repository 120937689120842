import { Theme, makeStyles, createStyles } from "@material-ui/core";

type Props = {
    media?: boolean;
};

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        listStyleType: "none",
        float: "left",
        position: "relative",
        display: "block",
    },
    link: {
        textDecoration: "none",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 15,
        margin: (props: Props) => (props.media? "0" : "0 15px"),
        display: "block",
        position: "relative",
        color: theme.palette.text.primary
    },
    activeMenu: {
        color: theme.palette.primary.main
    },
}));
