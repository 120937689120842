import { useLiterals } from "$hooks/Translate/useLiterals";
import { useState } from "react"
import { userService } from "$services/core";
import { EventChange, IUser } from "@djordjeandjelkovic/medgress_common_react_modules";
import { userProfile } from "$config/config";
import { useMessage } from "$helper/useMessage";

interface IState {
    password: string;
    confirmPassword: string;
}

interface IProps {
    user: IUser | undefined;
}

interface IActions {
    handleSetPassword: () => void;
    handleChange: (event: EventChange<string>) => void;
}

const useSetPasswordState = (props: IProps): [IState, IActions] => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const { user } = props;

    const { showError, showSuccess } = useMessage();
    const getLiteral = useLiterals();

    const isOutOfRange = (password: string) => {
        return password.length < userProfile.passwordLengthMin || password.length > userProfile.passwordLengthMax;
    }

    const handleSetPassword = () => {
        if(!user) {
            return;
        }

        if(isOutOfRange(password)) {
            showError(getLiteral("login.error.invalid_password", userProfile.passwordLengthMin, userProfile.passwordLengthMax));
        }

        if (password !== confirmPassword) {
            showError(getLiteral("user_profile.user_details.form.change_password.password_match_error"));
            return;
        }

        userService
            .setPassword(user.id!, { password: password })
            .then(() => {
                showSuccess(getLiteral("user_profile.user_details.form.change_password.password_changed"));
            })
            .catch((error) => {
                console.error(error);
                showError(error);
            })
            .finally(() => {});
    };

    const handleChange = (event: EventChange<string>) => {
        const { target: { name, value }, } = event;

        switch (name) {
            case "new-password":
                setPassword(value as string);
                break;
            case "confirm-password":
                setConfirmPassword(value as string);
                break;
        }
    };

    return [{ password, confirmPassword}, { handleChange, handleSetPassword: handleSetPassword }]
}

export { useSetPasswordState }
