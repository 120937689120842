import * as React from "react";

import { useStyles } from "./styles";

export interface PageContainerProps {}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.root}>{children}</div>;
};

export { PageContainer };
