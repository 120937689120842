import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";

export const RegistrationComplete = () => {
    const classes = useStyles();
    const getLiteral = useLiterals();
    return (
        <div className={classes.signUpPageWrapper}>
            <Grid container justify="center" classes={{ root: classes.gridRoot }} spacing={3}>
                <Grid item xs={6} md={4}>
                    <Paper className={classes.successCreate}>
                        <div className={classes.successIconWrapper}>
                            <CheckCircleOutlineIcon className={classes.successIcon} />
                        </div>
                        <div className={classes.successCreateTextWrapper}>
                            <Typography variant="h5" className={classes.successCreateText}>
                                {getLiteral("sign-up.registration.text")}
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};
