import React from "react";
import { Button } from "@material-ui/core";
import { useLiterals } from "$hooks/Translate/useLiterals";

type ProceedCheckoutButtonProps = {
    eventId: string;
    onClick: (eventId: string) => void;
};

export const ProceedCheckoutButton = (props: ProceedCheckoutButtonProps) => {
    const { eventId, onClick } = props;
    const getLiteral = useLiterals();

    return (
        <Button color="primary" variant="contained" onClick={() => onClick(eventId)}>
            {getLiteral("ticket_cart.proceed_checkout")}
        </Button>
    );
};
