import React from "react";
import _map from "lodash/map";
import _keys from "lodash/keys";
import _first from "lodash/first";
import _reduce from "lodash/reduce";
import _groupBy from "lodash/groupBy";
import _sumBy from "lodash/sumBy";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useCheckoutSelectors } from "$redux/selectors";
import { TopBorderContainer } from "$components/core";
import { useStyles } from "./styles";
import { DECIMAL_PLACES } from "$constants/Numbers";
import { useTicketsUtils } from "$helper/useTicketsUtils";

type SideTotalProps = {
    eventId?: string;
};
export const SideTotal = (props: SideTotalProps) => {
    const { eventId } = props;
    const checkoutSelectors = useCheckoutSelectors(eventId);
    const classes = useStyles();
    const { showTotalSum } = useTicketsUtils();

    if (!eventId) return <Typography>Calculating...</Typography>;

    const tickets = _groupBy(checkoutSelectors.tickets, (ticket) => ticket.ticket.id);
    const total = _reduce(
        _keys(tickets),
        (sum, key) => sum + _sumBy(tickets[key], (ticket) => ticket.ticket.price!.amount),
        0
    );

    const firstTicketKey = _keys(tickets)[0];
    let currency: string = "USD"; // TODO this should not be fixed!
    if (firstTicketKey) currency = tickets[firstTicketKey][0].ticket.currency || currency;

    return (
        <TopBorderContainer className={classes.container}>
            <Grid container>
                {_map(_keys(tickets), (key) => {
                    const ticket = _first(tickets[key]);
                    return (
                        <Grid key={key} item xs={12} className={classes.ticketInfoContainer}>
                            <Typography variant="body1" className={classes.ticketTitle}>
                                {ticket?.ticket.title} x{tickets[key].length}
                            </Typography>
                            <Typography variant="body1" className={classes.ticketAmount}>
                                {showTotalSum(
                                    _sumBy(tickets[key], (ticket) => ticket.ticket.price!.amount).toFixed(DECIMAL_PLACES),
                                    currency
                                )}
                            </Typography>
                        </Grid>
                    );
                })}
            </Grid>
            <Divider />
            <Typography variant="h5" className={classes.totalTitle}>
                Total
            </Typography>
            <Typography variant="h5" className={classes.totalAmount}>
                {showTotalSum(total.toFixed(DECIMAL_PLACES), currency)}
            </Typography>
        </TopBorderContainer>
    );
};
