import _find from "lodash/find";
import _findIndex from "lodash/findIndex";

import {
    ON_CHANGE,
    VALIDATION,
    SET_ATTENDEE_ID,
    AttendeeAction,
} from "$redux/actions/AttendeeFields/attendeeFieldsActionTypes";
import { TicketCart } from "$models/types/TicketCart";
import { TicketsState } from "./ticketsCartReducer";

export function attendeeFieldsReducer(state: TicketsState, action: AttendeeAction): TicketsState {
    switch (action.type) {
        case ON_CHANGE: {
            if (action.name === "firstName") {
                return updateFirstName(state, action);
            }

            if (action.name === "lastName") {
                return updateLastName(state, action);
            }

            if (action.name === "email") {
                return updateEmail(state, action);
            }

            return updateFields(state, action);
        }
        case VALIDATION:
            return updateValidation(state, action);
        case SET_ATTENDEE_ID:
            return updateId(state, action);

        default:
            return state;
    }
}

const updateFirstName = (
    state: TicketsState,
    action: { index: number; eventId: string; value: string | string[] }
) => {
    const ticketCart = _getTicketCart(state, action.eventId, action.index);

    if (typeof ticketCart === "undefined" || typeof ticketCart.attendee === "undefined") {
        return state;
    }

    ticketCart.attendee = {
        ...ticketCart.attendee,
        firstName: {
            ...ticketCart.attendee.firstName,
            value: action.value as string,
        },
    };

    return { ...state };
};

const updateLastName = (
    state: TicketsState,
    action: { index: number; eventId: string; value: string | string[] }
) => {
    const ticketCart = _getTicketCart(state, action.eventId, action.index);

    if (typeof ticketCart === "undefined" || typeof ticketCart.attendee === "undefined") {
        return state;
    }

    ticketCart.attendee = {
        ...ticketCart.attendee,
        lastName: {
            ...ticketCart.attendee.lastName,
            value: action.value as string,
        },
    };

    return { ...state };
};

const updateEmail = (
    state: TicketsState,
    action: { index: number; eventId: string; value: string | string[] }
) => {
    const ticketCart = _getTicketCart(state, action.eventId, action.index);

    if (typeof ticketCart === "undefined" || typeof ticketCart.attendee === "undefined") {
        return state;
    }

    ticketCart.attendee = {
        ...ticketCart.attendee,
        email: {
            ...ticketCart.attendee.email,
            value: action.value as string,
        },
    };

    return { ...state };
};

const updateFields = (
    state: TicketsState,
    action: { index: number; eventId: string; name: string; value: string | string[] }
) => {
    const ticketCart = _getTicketCart(state, action.eventId, action.index);

    if (typeof ticketCart === "undefined" || typeof ticketCart.attendee === "undefined") {
        return state;
    }

    let fields = [...ticketCart.attendee.fields];
    const fieldIndex = _findIndex(fields, (field) => field.value.id === action.name);
    if (fieldIndex === -1) {
        return state;
    }

    ticketCart.attendee.fields[fieldIndex] = {
        ...ticketCart.attendee.fields[fieldIndex],
        value: {
            ...ticketCart.attendee.fields[fieldIndex].value,
            value: action.value as string,
        },
    };

    return { ...state };
};

const updateValidation = (
    state: TicketsState,
    action: { eventId: string; index: number; attendee: TicketCart }
) => {
    const ticketCart = _getTicketCart(state, action.eventId, action.index);

    if (
        typeof ticketCart === "undefined" ||
        typeof ticketCart.attendee === "undefined" ||
        typeof action.attendee.attendee === "undefined"
    ) {
        return state;
    }

    ticketCart.attendee = {
        ...ticketCart.attendee,
        firstName: { ...action.attendee.attendee.firstName },
        lastName: { ...action.attendee.attendee.lastName },
        email: { ...action.attendee.attendee.email },
        fields: [...action.attendee.attendee.fields],
    };

    return { ...state };
};

const updateId = (state: TicketsState, action: { index: number; eventId: string; id: string }) => {
    const ticketCart = _getTicketCart(state, action.eventId, action.index);

    if (typeof ticketCart === "undefined" || typeof ticketCart.attendee === "undefined") {
        return state;
    }

    ticketCart.attendee.id = { ...ticketCart.attendee.id, value: action.id };

    return { ...state };
};

const _getTicketCart = (state: TicketsState, eventId: string, index: number) => {
    return _find(
        _getTicketsForEvent(state, eventId),
        (ticketCart) => ticketCart.event.id === eventId && ticketCart.checkoutIndex === index
    );
};


const _getTicketsForEvent = (state: TicketsState, eventId: string) => {
    return state[eventId].tickets;
};
