import React from "react";
import ContentLoader from "react-content-loader";
import { useTheme } from '@material-ui/core/styles';

export const EventDetailsLoader = () => {
    const theme = useTheme();

    return (
        <ContentLoader speed={2} viewBox="0 0 500 700" backgroundColor={theme.palette.grey[400]} foregroundColor={theme.palette.grey[300]}>
            <rect x="0" y="0" rx="1" ry="1" width="500" height="98" />
            <rect x="0" y="101" rx="1" ry="1" width="342" height="245" />
            <rect x="351" y="100" rx="1" ry="1" width="150" height="245" />
            <rect x="0" y="351" rx="1" ry="1" width="342" height="245" />
            <rect x="351" y="351" rx="1" ry="1" width="150" height="245" />
        </ContentLoader>
    );
}