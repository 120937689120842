import { createStyles, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const styles = makeStyles(() => createStyles({
    wrapper: {
        maxWidth: 350,
        margin: "0 auto",
    },
    typography: {
        color: grey[500],
        fontSize: "0.9rem",
    },
}));
