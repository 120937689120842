import React, { memo } from "react";
import { Card, CardContent, CardActions, Typography, Collapse } from "@material-ui/core";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { ExpandButton } from "$components/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useTicketsCartState } from "./useTicketsCartState";
import { useStyles } from "./styles";
import { TicketsInCart } from "./TicketsInCart";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _find from "lodash/find";
import { ProceedCheckoutButton } from "./ProceedCheckoutButton";
import { CartHeader } from "./CartHeader";

interface ITicketCartProps {
    onClose: () => void;
}

export const TicketCart = memo((props: ITicketCartProps) => {
    const { onClose } = props;
    const [state, actions] = useTicketsCartState({ onClose });
    const getLiteral = useLiterals();
    const classes = useStyles();

    if (_isEmpty(state.cartTickets))
        return (
            <div className={classes.noTickets}>
                <Typography variant="body1">{getLiteral("ticket_cart.no_tickets")}</Typography>
                <RemoveShoppingCartIcon />
            </div>
        );

    return (
        <div>
            {_map(state.cartTickets, (cartItem) => {
                const expand = _find(state.expand, (doExpand) => doExpand.id === cartItem.event.id)?.expand;

                return (
                    <Card key={cartItem.event.id}>
                        <CartHeader item={cartItem} />
                        <Collapse in={expand} timeout="auto" unmountOnExit>
                            <CardContent>
                                <TicketsInCart
                                    tickets={cartItem.tickets}
                                    event={cartItem.event}
                                    onRemove={actions.handleRemoveTicket}
                                />
                            </CardContent>
                        </Collapse>
                        <CardActions>
                            <ProceedCheckoutButton
                                eventId={cartItem.event.id}
                                onClick={actions.handleProceedCheckout}
                            />
                            <ExpandButton
                                id={cartItem.event.id}
                                onClick={actions.handleExpandClick}
                                expand={expand}
                            />
                        </CardActions>
                    </Card>
                );
            })}
        </div>
    );
}, propsAreEqual);

function propsAreEqual(prevProps: ITicketCartProps, nextProps: ITicketCartProps) {
    return true;
}
