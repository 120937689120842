import { useEffect, useState } from "react";
import { attendeeService } from "$services/attendee.service";
import { EventChange, IAttendee, ErrorType } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useParams } from "react-router";
import { useMessage } from "$helper/useMessage";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { ApiErrors } from "@djordjeandjelkovic/medgress_common_react_modules";
import _find from "lodash/find";
import { useQuery } from "./useQuery";

export const useUpdateAttendee = () => {
    const [attendee, setAttendee] = useState<ErrorType<IAttendee, ""> | null>();
    const [isLoading, setLoading] = useState(false);
    const { attendeeId } = useParams<{ attendeeId: string }>();
    const { d, h } = useQuery();
    const { showError, showSuccess } = useMessage();
    const getLiteral = useLiterals();

    useEffect(() => {
        const fetchData = async () => {
            if (d === null || h === null) {
                showError(getLiteral("attendees.update.qr_code.invalid_request"));
                setAttendee(null);
                return;
            }

            try {
                setLoading(true);

                const data = await attendeeService.get(attendeeId, {
                    date: d,
                    hash: h,
                });
                setAttendee(data);
            } catch (error) {
                console.error("error", error);
                showError(ApiErrors[String(error)]);
                setAttendee(null);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [attendeeId, d, h, showError, getLiteral]);

    const handleUpdate = async () => {
        try {
            setLoading(true);
            await attendeeService.update(attendee!, { date: d!, hash: h! });
            showSuccess("Updated.");
        } catch (error) {
            console.error(error);
            showError(ApiErrors[String(error)]);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: EventChange<string>) => {
        const { name, value } = e.target;

        setAttendee((prevState) => {
            if (!prevState) {
                return prevState;
            }

            const fields = [...prevState!.fields];
            const state = { ...prevState };

            if (name === "firstName") {
                state.firstName!.value = value;
            }

            if (name === "lastName") {
                state.lastName!.value = value;
            }

            if (name === "email") {
                state.email!.value = value;
            }

            const field = _find(fields, (f) => f.value.id === name);
            if (field) {
                field.value.value = value;
            }

            state.fields! = fields;

            return state;
        });
    };

    return [
        { attendee, isLoading },
        { handleChange, handleUpdate },
    ];
};
