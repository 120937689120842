import React from "react";
import { Divider, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";

type ContainerTitleProps = {
    title?: string;
};

const ContainerTitle = (props: ContainerTitleProps) => {
    const classes = useStyles();
    const { title } = props;

    if(!title) {
        return null;
    }

    return (
        <>
            <Typography variant="h6" className={classes.title}>
                {title}
            </Typography>
            <Divider />
        </>
    );
}

type TopBorderContainerProps = {
    children: React.ReactNode;
    className?: string;
    title?: string;
};

export const TopBorderContainer = (props: TopBorderContainerProps) => {
    const { children, className, title } = props;
    const classes = useStyles();
    return (
        <Paper className={clsx(classes.container, className)}>
            <ContainerTitle title={title} />
            {children}
        </Paper>
    );
};
