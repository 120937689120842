interface ICurrencyDecimalPoint {
    [key: string]: number;
}

const CurrencyDecimalPointMultiplayer: ICurrencyDecimalPoint = {
    USD: 100,
    EUR: 100,
    AED: 100,
};

export default CurrencyDecimalPointMultiplayer;
