import {
    SET_CARD_HOLDER,
    SET_CARD_NUMBER,
    SET_CARD_TYPE,
    SET_CVC,
    SET_EXPIRE_MONTH,
    SET_EXPIRE_YEAR,
    SET_SIGNATURE,
    RESET,
    SET_TERMS,
} from "./paymentActionTypes";

export const setCardHolder = (cardHolder: string) => ({
    type: SET_CARD_HOLDER,
    cardHolder,
});

export const setCardNumber = (cardNumber: string) => ({
    type: SET_CARD_NUMBER,
    cardNumber,
});

export const setCardType = (cardType: string) => ({
    type: SET_CARD_TYPE,
    cardType,
});

export const setCvc = (cvc: string) => ({
    type: SET_CVC,
    cvc,
});

export const setExpireMonth = (expireMonth: string) => ({
    type: SET_EXPIRE_MONTH,
    expireMonth,
});

export const setExpireYear = (expireYear: string) => ({
    type: SET_EXPIRE_YEAR,
    expireYear,
});

export const setSignature = (signature: string) => ({
    type: SET_SIGNATURE,
    signature,
});

export const reset = () => ({
    type: RESET,
});

export const setTerms = (terms: boolean) => ({
    type: SET_TERMS,
    terms,
});
