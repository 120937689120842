import { useState, useCallback } from "react";
import _findIndex from "lodash/findIndex";
import { EventChange, TicketDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { ITicketCount } from "./useAddTickets";

export default (tickets: TicketDTO[] | undefined) => {
    const [ticketsCount, setTicketsCount] = useState<TicketsCount>(TicketsCount.default);

    const handleChangeTicketCount = useCallback(
        (event: EventChange<string>, ticketId: string) => {
            setTicketsCount((previousState: TicketsCount) => {
                if (tickets === undefined) {
                    return previousState;
                }

                const selectedTicket = tickets.find((ticketItem) => ticketItem.id === ticketId);
                if (selectedTicket === undefined) {
                    console.log("Something went wrong while changing ticket count. TicketId not found.");
                    return previousState;
                }

                let newTicketsCountList = previousState.copy();
                const existingTicket = newTicketsCountList.find((ticket) => ticket.ticket.id === ticketId);
                if (existingTicket === undefined) {
                    return previousState.add(selectedTicket, Number(event.target.value));
                }

                existingTicket.count = Number(event.target.value);
                return newTicketsCountList;
            });
        },
        [tickets]
    );

    const resetTicketsCount = () => setTicketsCount(TicketsCount.default);

    return { ticketsCount, handleChangeTicketCount, resetTicketsCount };
};

export class TicketsCount {
    tickets: ITicketCount[];

    constructor() {
        this.tickets = [];
    }

    static default(): TicketsCount {
        return new TicketsCount();
    }

    copy(): TicketsCount {
        const obj = {
            ...this,
            tickets: [...this.tickets],
        };
        Object.setPrototypeOf(obj, TicketsCount.prototype);
        return obj;
    }

    add(ticket: TicketDTO, count: number): TicketsCount {
        const obj = {
            ...this,
            tickets: [...this.tickets, { ticket, count }],
        };
        Object.setPrototypeOf(obj, TicketsCount.prototype);
        return obj;
    }

    find(action: (t: ITicketCount) => boolean) {
        return this.tickets.find(action);
    }

    hasSelectedTicket(id?: string): boolean {
        if (!id) {
            return _findIndex(this.tickets, (ticket) => ticket.count > 0) !== -1;
        }

        return _findIndex(this.tickets, (ticket) => ticket.ticket.id === id && ticket.count > 0) !== -1;
    }
}
