import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        expireDate: {
            width: "60px",
        },
        cardInfo: {
            fontSize: 24,
            padding: "15px 0",
        },
        cardTotal: {
            fontWeight: "bold",
            fontSize: 24,
            padding: 15,
            borderTop: "1px solid lightgrey",
            marginTop: 70,
            textAlign: "right",
        },
        payment: {
            margin: "0 auto",
        },
        paymentProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        successInfo: {
            textAlign: "center",
            paddingTop: 200,
            paddingBottom: 200,
        },
        successInfoText: {
            padding: "1em",
            fontSize: "2em",
        },
        termsTitle: {
            padding: "20px 0",
        },
        cardBorder: {
            borderBottom: "1px solid lightgrey",
        },
        ticketAmount: {
            float: "right",
        },
        paymentBtn: {
            marginTop: 30,
            marginBottom: 10,
        },
    })
);
