import React from "react";
import ContentLoader from "react-content-loader";
import { useTheme } from '@material-ui/core/styles';

const AttendeeUpdateLoader = () => {
    const theme = useTheme();

    return (
        <ContentLoader speed={1} viewBox="0 0 400 600" backgroundColor={theme.palette.grey[400]} foregroundColor={theme.palette.grey[300]}>
            <rect x="40" y="40" rx="0" ry="0" width="320" height="30" />
            <rect x="40" y="80" rx="0" ry="0" width="320" height="30" />
            <rect x="40" y="120" rx="0" ry="0" width="320" height="30" />
            <rect x="40" y="160" rx="0" ry="0" width="320" height="30" />
            <rect x="40" y="200" rx="0" ry="0" width="320" height="30" />
            <rect x="40" y="240" rx="0" ry="0" width="320" height="30" />
            <rect x="40" y="280" rx="0" ry="0" width="320" height="30" />
            <rect x="80" y="330" rx="0" ry="0" width="240" height="30" />
        </ContentLoader>
    );
}

export default AttendeeUpdateLoader;
