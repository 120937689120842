import {
    RESET,
    RESET_ERROR,
    SET_CONFIRM_PASSWORD,
    SET_EMAIL,
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_PASSWORD,
    SET_PHONE,
    SET_VALIDATION_RESULT,
} from "./actionTypes";
import { SignUpFormState } from "./SignUpForm";

export const setFirstName = (firstName: string, error?: string) => ({
    type: SET_FIRST_NAME,
    payload: { firstName, error },
});

export const setLastName = (lastName: string, error?: string) => ({
    type: SET_LAST_NAME,
    payload: { lastName, error },
});

export const setPhone = (phone?: string, error?: string) => ({
    type: SET_PHONE,
    payload: { phone, error },
});

export const setEmail = (email: string, error?: string) => ({
    type: SET_EMAIL,
    payload: { email, error },
});

export const setPassword = (password: string, error?: string) => ({
    type: SET_PASSWORD,
    payload: { password, error },
});

export const setConfirmPassword = (confirmPassword: string, error?: string) => ({
    type: SET_CONFIRM_PASSWORD,
    payload: { confirmPassword, error },
});

export const resetStateError = () => ({ type: RESET_ERROR });

export const setFormValidationResult = (output: SignUpFormState) => ({
    type: SET_VALIDATION_RESULT,
    payload: output,
});

export const reset = () => ({ type: RESET });
