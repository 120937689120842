import React from "react";

import { Container, Divider, Grid, Typography } from "@material-ui/core";

import Accordition from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";
import LanguageIcon from "@material-ui/icons/Language";
import EmailIcon from "@material-ui/icons/Email";
import DescriptionIcon from "@material-ui/icons/Description";

import { Company } from "@djordjeandjelkovic/medgress_common_react_modules";

import { useStyles } from "./styles";

type CompanyDetailsProps = {
    company?: Company;
};

export const CompanyDetails = (props: CompanyDetailsProps) => {
    const { company } = props;
    const classes = useStyles();

    if(!company) {
        return null;
    }

    return (
        <Container className={classes.companyDetailsRoot}>
            <Accordition className={classes.companyDetailsAccordition} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {company?.name}`s info
                </AccordionSummary>
                <AccordionDetails className={classes.companyDetailsContainer} classes={{ root: classes.MuiAccordionActions}}>
                    <Grid container>
                        <Grid item lg={4}>
                            <Typography variant="body2">
                                <BusinessIcon classes={{ root: classes.MuiSvgIcon }} />
                                Company name
                            </Typography>
                            <Divider />
                            <Typography variant="body1">{ company?.name }</Typography>                            
                        </Grid>
                        <Grid item lg={4}>
                            <Typography variant="body2">
                                <BusinessIcon classes={{ root: classes.MuiSvgIcon }} />
                                Short name
                            </Typography>
                            <Divider />
                            <Typography variant="body1">{ company?.shortName }</Typography>                            
                        </Grid>
                        <Grid item lg={4}>
                            <Typography variant="body2">
                                <HomeIcon classes={{ root: classes.MuiSvgIcon }} />
                                Address
                            </Typography>
                            <Divider />
                            <Typography variant="body1">{ company?.address }</Typography>                            
                        </Grid>
                        <Grid item lg={4} className={classes.companyDetailsLabel}>
                            <Typography variant="body2">
                                <DescriptionIcon classes={{ root: classes.MuiSvgIcon }} />
                                Description
                            </Typography>
                            <Divider />
                            <Typography variant="body1">{ company?.description }</Typography>                            
                        </Grid>
                        <Grid item lg={4} className={classes.companyDetailsLabel}>
                            <Typography variant="body2"><EmailIcon classes={{ root: classes.MuiSvgIcon }} /> E-mail</Typography>
                            <Divider />
                            <Typography variant="body1">{ company?.email }</Typography>                            
                        </Grid>
                        <Grid item lg={4} className={classes.companyDetailsLabel}>
                            <Typography variant="body2"><LanguageIcon classes={{ root: classes.MuiSvgIcon }} /> Web-site</Typography>
                            <Divider />
                            <Typography variant="body1">{ company?.website }</Typography>                            
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordition>
        </Container>
    );
}
