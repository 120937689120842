import { useTicketsCart } from "$components/core";
import { useDispatch } from "react-redux";
import * as CartTimeoutActionCreators from "$redux/actions/CartTimeout/cartTimeoutActions";
import { bindActionCreators } from "redux";
import { EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

type Actions = {
    event: EventDTO;
};

export const useTicketsActionCreators = ({ event }: Actions) => {
    const dispatch = useDispatch();
    const [, ticketsCart] = useTicketsCart(event.id);
    const { initTimeout } = bindActionCreators(CartTimeoutActionCreators, dispatch);

    return { initTimeout, ...ticketsCart };
};
