import { useLiterals } from "$hooks/Translate/useLiterals";
import { format } from "date-fns";
import { DateDefaultFormat, TimeDefaultFormat } from "$constants/Dates";
import { TicketDTO, TicketStatusEnum } from "@djordjeandjelkovic/medgress_common_react_modules";
import { DECIMAL_PLACES } from "$constants/Numbers";

export const useTicketsUtils = () => {
    const literals = useLiterals();

    const showTotalSum = (sum: string | undefined, currency: string) => {
        if (!sum) {
            return "";
        }
        return sum === "0.00" ? literals("ticket_cart.payment.free_ticket") : sum + " " + currency;
    };

    const getPriceInfo = (ticket: TicketDTO) => {
        if (ticket.status === TicketStatusEnum.AVAILABLE) {
            return ticket.price?.amount === 0
                ? literals("event_details.ticket.free")
                : ticket.currency + " " + ticket.price?.amount.toFixed(DECIMAL_PLACES);
        }

        if (ticket.status === TicketStatusEnum.NO_AVAILABLE) {
            return literals("event_details.ticket.not_available_tickets");
        }

        if (ticket.status === TicketStatusEnum.SOLD_OUT) {
            return literals("event_details.ticket.sold_out");
        }

        console.log("Unknown ticket price info: " + JSON.stringify(ticket));
        return literals("event_details.ticket.unknown");
    };

    return { showTotalSum, getPriceInfo };
};

export const ticketName = (t: TicketDTO): string => {
    if (t.date == null) {
        return t?.title.toUpperCase();
    }
    return (
        `${t.title} - ${format(convertToDate(t.date!), DateDefaultFormat)}` +
        (isFullDayTicket(t)
            ? ""
            : ` - ${format(convertToDate(t.partDay.from), TimeDefaultFormat)} - ${format(
                  convertToDate(t.partDay.to),
                  TimeDefaultFormat
              )}`)
    );
};

const isFullDayTicket = (ticket: TicketDTO) => {
    return ticket.partDay.from == null && ticket.partDay.to == null;
};

const convertToDate = (date: Date): number | Date => {
    if (typeof date === "string") {
        return new Date(date);
    }

    return date;
};

