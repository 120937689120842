import React, { MouseEvent } from "react";
import { useSelector } from "react-redux";
import { Badge, IconButton } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ReduxStore } from "$redux/reducers";
import _reduce from "lodash/reduce";

type CartBadge = {
    onClick: (event: MouseEvent<HTMLElement>) => void;
};

export const CartBadge = (props: CartBadge) => {
    const { onClick } = props;
    const numberOfTicketsInCart = useSelector<ReduxStore, number>((store) =>
        _reduce(
            store.ticketsCart,
            (numberOfTickets, eventCart) => numberOfTickets + eventCart.tickets?.length || 0,
            0
        )
    );

    return (
        <IconButton color="inherit" onClick={onClick}>
            <Badge badgeContent={numberOfTicketsInCart} color="primary">
                <ShoppingCartIcon />
            </Badge>
        </IconButton>
    );
};
