import React, { useEffect, useCallback } from "react";
import { Button } from "@material-ui/core";
import { useStyles } from "../styles";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { LocalStorage } from "$constants/LocalStorage";
import * as TicketsCartActionCreators from "$redux/actions/TicketsCart/ticketsCartActions";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

const SuccessPayment = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const getLiteral = useLiterals();
    const location = useLocation<{ eventId: string }>();

    const eventId = location.state.eventId;

    const { removeAllTickets } = bindActionCreators(TicketsCartActionCreators, dispatch);

    const clearCart = useCallback(() => {
        removeAllTickets(eventId);
        removeCurrentStepFromLocalStorage();
    }, [eventId, removeAllTickets]);

    useEffect(() => {
        clearCart();
    }, [clearCart]);

    return (
        <div className={classes.successInfo}>
            <div className={classes.successInfoText}>{getLiteral("ticket_cart.payment.success_pay")}</div>
            <div className={classes.successInfoText}>{getLiteral("ticket_cart.payment.check_email")}</div>
            <Button color="primary" variant="outlined" href="/">
                {getLiteral("ticket_cart.payment.button.back_to_events")}
            </Button>
        </div>
    );
};

const removeCurrentStepFromLocalStorage = () => {
    localStorage.removeItem(LocalStorage.CURRENT_STEP);
};

export { SuccessPayment };
