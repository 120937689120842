import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { authenticationService } from "$services/core";

export const PrivateRoute = (props: RouteProps) => {
    const { location } = props;
    const isLoggedIn = authenticationService.isLoggedIn();
    if (!isLoggedIn) {
        return (
            <Redirect
                to={{
                    pathname: `/login`,
                    search: `?returnUrl=${location?.pathname}`,
                    state: { from: location },
                }}
            />
        );
    }
    
    return <Route {...props} />;
};
