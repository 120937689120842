import * as api from "$helper/api";
import { ContactUs } from "@djordjeandjelkovic/medgress_common_react_modules";

const send = (eventId: string, contactUs: ContactUs) => {
    return api.post<ContactUs, any>(`/contact-us/events/${eventId}`, contactUs);
}

export const contactUsService = {
    send,
};
