import * as api from "$helper/api";
import { apiPrefix } from "../serviceConstants";

import { Company } from "@djordjeandjelkovic/medgress_common_react_modules";

export const getList = (): Promise<Company[]> => {
    return api.get<Company[]>(apiPrefix.companies);
};

export default getList;
