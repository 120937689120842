import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: `${theme.typography.pxToRem(20)}`,
            margin: `${theme.typography.pxToRem(40)} auto`,
        },
        totalTitle: {
            textAlign: "right",
        },
        totalAmount: {
            textAlign: "right",
        },
        ticketTitle: {
            float: "left",
        },
        ticketAmount: {
            float: "right",
        },
        ticketInfoContainer: {
            padding: `${theme.typography.pxToRem(5)}`,
        },
    })
);

export { useStyles };
