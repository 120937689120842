const en: { [key: string]: any } = {
    "activate_account.wait": "Please wait... You will be redirected to login.",

    "attendee.form.first_name": "First name",
    "attendee.form.last_name": "Last name",
    "attendee.form.email_address": "E-mail Address",

    "attendees.update.qr_code.invalid_request": "Invalid request. Please, scan QR code again.",

    "checkout.steps.error_in_validation": "Please, check fields with error (with red border).",
    "checkout.error_message": "Problem with connection. Please try later.",
    "checkout.title": "CHECKOUT",
    "checkout.action.remove_ticket": "Remove ticket",

    "common.button.update": "Update",
    "common.button.next": "Next",
    "common.button.back": "Back",
    "common.button.sign_in": "Sign in",
    "common.button.sign_up": "Sign up",
    "common.button.logout": "Logout",
    "common.button.finish": "Finish",

    "common.error.general_error": "Something went wrong. Please try later.",
    "common.error.invalid_request": "You don't have permission to see this page or url is invalid.",

    "contact_us.title": "Contact Us",
    "contact_us.company_name": "Diaedu Management Consultancy",
    "contact_us.description": "All enquiries and correspondence in relation to the conference should be directed to DiaEdu. If you would like to get in touch with us, please choose your method of correspondence and we will do our best to get back to you as quickly as possible.",
    "contact_us.form.name": "Name",
    "contact_us.form.name.placeholder": "John Davids",
    "contact_us.form.email": "E-mail",
    "contact_us.form.email.placeholder": "user@mail.com",
    "contact_us.form.message": "Message",
    "contact_us.form.message.placeholder": "Enter your message",
    "contact_us.form.button.send": "Send",
    "contact_us.form.success_submit": "Thanks for contacting us! We will be in touch with you shortly.",
    "contact_us.find_us": "How to find us?",
    "contact_us.phone": "+971 4 4532975 907",
    "contact_us.address": "Dusseldorf Business Point, Al Barsha 1, Dubai, United Arab Emirates",
    "contact_us.employees.managing_director": "Managing Director & Sponsorship",
    "contact_us.employees.managing_director.name": "Christel Have",
    "contact_us.employees.managing_director.phone": "+971 50 5582916",
    "contact_us.employees.managing_director.email": "chav@diaedu.com",
    "contact_us.employees.marketing": "Marketing & Operations",
    "contact_us.employees.marketing.name": "Basil Kadara",
    "contact_us.employees.marketing.phone": "+971 50 9299239",
    "contact_us.employees.marketing.email": "bkadara@diaedu.com",
    "contact_us.employees.general_inquiry": "General Inquiry & Registration",
    "contact_us.employees.general_inquiry.name": "Saranya Jinu",
    "contact_us.employees.general_inquiry.phone": "+971 54 3457867",
    "contact_us.employees.general_inquiry.email": "sara@diaedu.com",
    "contact_us.employees.it": "IT",
    "contact_us.employees.it.name": "Milos Stojanovic",
    "contact_us.employees.it.phone": "+971 52 9696860",
    "contact_us.employees.it.email": "milosh@diaedu.com",
    "contact_us.error.invalid_name": "Name must between 5 and 100 characters",
    "contact_us.error.invalid_email": "Invalid e-mail address",
    "contact_us.error.invalid_message": "Message must between 5 and 1000 characters",

    "engine.response.AUTH_GOOGLE_INVALID_TOKEN": "Invalid Google authorization. Please, try different type of Sign In.",
    "engine.response.ATTENDEES_INSERT_NOT_POSSIBLE_DUE_TICKETS_LIMIT_EXCEEDED": "Attendee registration is not possible due tickets limit. Please, contact event organizator.",
    "engine.response.GENERAL_ENGINE_ERROR": "Something went wrong. Please try later.",

    "event_details.info.contact_us": "Contact organizer",
    "event_details.info.panel.unavailable": "Currently, we have no information.",
    "event_details.info.start_date": "Start date",
    "event_details.info.end_date": "End date",
    "event_details.info.location": "Location",
    "event_details.info.venue": "Venue",
    "event_details.info.host": "Host",
    "event_details.info.event_type": "Event type",
    "event_details.not_published_event": "No information about this event.",
    "event_details.ticket_count.label": "# Tickets",
    "event_details.ticket_count.options.0": "None",
    "event_details.ticket_count.options.1": "1",
    "event_details.ticket_count.options.2": "2",
    "event_details.ticket_count.options.3": "3",
    "event_details.ticket_count.options.4": "4",
    "event_details.ticket_count.options.5": "5",
    "event_details.ticket_count.options.6": "6",
    "event_details.ticket_count.options.7": "7",
    "event_details.ticket_count.options.8": "8",
    "event_details.ticket_count.options.9": "9",
    "event_details.ticket_count.options.10": "10",
    "event_details.ticket.free": "Free",
    "event_details.ticket.not_available_tickets": "No available tickets",
    "event_details.ticket.sold_out": "Sold out",
    "event_details.ticket.unknown": "Unknown",
    "event_details.ticket.button.add_to_cart": "Add to cart",
    "event_details.ticket.button.buy_now": "Buy now",
    "event_details.ticket.title": "Buy tickets",
    "event_details.ticket.success_add": "Successfully added ticket(s)",
    "event_details.location.title": "Event Location",
    "event_details.tickets.attendee_fields.error": "Can't add ticket. Tickets number reached limit",

    "events.events_filter.error_start_date": "Missing start date",
    "events.events_filter.event_name": "Event name",
    "events.events_filter.city": "City",
    "events.events_filter.event_type": "Event type",
    "events.events_filter.nature_type": "Nature type",
    "events.events_filter.location": "Location",
    "events.events_filter.date_from": "Date from",
    "events.events_filter.clear": "Clear search",
    "events.events_filter.upcoming_events_part0": "Upcoming",
    "events.events_filter.upcoming_events_part1": "Events",

    "events.event_list.no_events": "We can't find any events that match your search criteria.",
    "events.event_list.view_event": "View Event >>",
    "events.event_list.load_more": "Load more",

    "events.event_type.-1": "All",
    "events.event_type.0": "Educational conference",
    "events.event_type.1": "Entertainment",
    "events.event_type.2": "Event",
    "events.event_type.3": "Expo",
    "events.event_type.4": "Meetings and seminars",
    "events.event_type.5": "Social gathering",
    "events.event_type.6": "Others",

    "events.location_type.-1": "All",
    "events.location_type.0": "Physical",
    "events.location_type.1": "Virtual",
    "events.location_type.2": "Hybrid",

    "footer.app_name": "MedgressEvents",
    "footer.app_description": "MedgressEvents is an event guide and innovative ticketing platform.",
    "footer.tickets_hotline.title": "Ticket hotline",
    "footer.tickets_hotline.working_hours": "Sun - Thu 10:00 - 16:00",
    "footer.need_help.title": "Need help?",
    "footer.need_help.button": "Support center",
    "footer.event_organizer.title": "Event organizers",
    "footer.event_organizer.button": "Add my event",
    "footer.event_organizer.description": "Share your events around the world",

    "history_purchase.reference_id": "Reference Id",
    "history_purchase.event_name": "Event Name",
    "history_purchase.number_of_tickets": "# Tickets",
    "history_purchase.payment_id": "Payment Id",
    "history_purchase.total": "Total",
    "history_purchase.payment_status": "Payment Status",
    "history_purchase.empty_purchase": "There are no past purchases.",
    "history_purchase.title": "Purchase history",
    "history_purchase.purchase_date": "Purchase date",

    "history_purchase_details.title": "Purchase history details",
    "history_purchase_details.attendee_does_not_exist": "Attendee details are not available. Probably, attendee is removed.",

    "login.email": "Email",
    "login.error.invalid_email": "Invalid e-mail address.",
    "login.error.invalid_password": "Password must be between {0} and {1} characters.",
    "login.error.invalid_email_or_password": "Invalid e-mail or password.",
    "login.password": "Password",
    "login.login_button": "Login",
    "login.error.password_can_be_updated_only_with_login_with_email": "Login with e-mail to change password.",

    n_a: "N/A",

    "nav_bar.user_profile.purchase_history.menu_item": "Purchase history",

    "header.menu.events": "EVENTS",
    "header.menu.terms_and_conditions": "TERMS & CONDITIONS",
    "header.menu.contact_us": "CONTACT US",

    "person_info.email": "email",
    "person_info.phone": "phone",

    "sign_in.title": "Sign in",
    "sign_in.form.email": "E-mail",
    "sign_in.form.password": "Password",
    "sign_in.form.submit": "Sign in",
    "sign_in.OR": "OR",
    "sign_in.sign_up": "Don't have an account ? Sign up here",
    "sign_in.with_google": "Sign in with Google",

    "sign_up.title": "Welcome",
    "sign_up.form.first_name": "First name",
    "sign_up.form.last_name": "Last name",
    "sign_up.form.email": "E-mail address",
    "sign_up.form.password": "Password",
    "sign_up.form.confirm_password": "Confirm password",
    "sign_up.form.phone_number": "Phone number",
    "sign_up.form.submit": "Create account",
    "sign_up.with_google": "Sign up with Google",
    "sign_up.validation.required": "Required",
    "sign_up.validation.password_length": "Password must be between {0} and {1} characters long.",
    "sign_up.validation.password_match": "Passwords doesn't match",
    "sign_up.validation.recaptcha_value": "Recaptcha value is not assigned.",
    "sign_up.validation.invalid_email": "Invalid e-mail address.",
    "sign_up.success_message": "You account has been created. Check your e-mail to activate account.",
    "sign_up.response.EMAIL_ALREADY_EXISTS": "User with email already exists.",
    "sign_up.response.USERS_INVALID_EMAIL_FORMAT": "Invalid e-mail address.",
    "sign-up.registration.title": "MedGress Registration Portal",
    "sign-up.registration.text": "You account has been created. \nCheck your email to activate your account. ",
    "sign-up.recaptcha.error": "Recaptcha error. Please try again later.",
    "sign-up.recaptcha.expire": "Recaptcha expire. Please try again.",
    "sign-up.forgot_password.success": "Password reset. Check email for more information.",
    "sign-up.forgot_password.ERROR_USER_DOESNT_EXISTS": "User with provided email doesn't exists.", 
    "sign-up.forgot_password.btn_reset": "Reset password.", 
    "sign-up.forgot_password.lnk_forgot_password": "Forgot password?", 

    "subscribe.title": "Subscribe",
    "subscribe.description": "Let's subscribe to get in touch with the latest events!",    
    "subscribe.button": "Subscribe",
    "subscribe.invalid_email": "E-mail is invalid",
    "subscribe.success_subscription": "Thanks for subscribe!",

    "terms_and_conditions.title": "Terms & Conditions",
    "terms_and_conditions.description1": "Registration is confirmed only upon payment receipt. Electronic means of payment are confirmed as a proof of payment upon receipt of payment only through PayPal.com. Offline means of payment are confirmed as a proof of payment only when a copy of the bank receipt together with the unique identification number, as received in the registration confirmation email, is communicated to DiaEdu Management Consultants through the email provided in the instructions stated on the confirmation page.",
    "terms_and_conditions.description2": "Offline means of payments will be confirmed only when all bank transmission fees will be covered as per the transmitter banking applicable fees. If payment is processed one week prior to the launch date of the event, onsite authorization will be required by providing a hard copy with proof of payment. The registration fee applies as per the date of payment stated on the registration page.",
    "terms_and_conditions.description3": "All accommodation and transportation requests issued subject to terms and conditions under which transportation, accommodation or other items or services are from time to time provided by the service providers. DiaEdu Management Consultants make no representation or warranty with respect to the standard of service or accommodation given or provided by any service provider and the registrant hereby acknowledges that he/she has not relied upon any such representation or warranty by or on behalf of DiaEdu Management Consultants.",
    "terms_and_conditions.description4": "Accommodation fees are fair quoted as per third-party agreements. Failure to check-in on the date requested on the accommodation page applies a no-show non-refundable fee. DiaEdu Management Consultants is not liable for injury, damage, loss, accident, delay or irregularity, additional expense or liability occasioned to any person or property for all in-house guests intended for accommodation on the accommodation page. Transportation fees are fair quoted as per third-party agreements.",
    "terms_and_conditions.description5": "Failure to show up on the date requested on the transportation page applies a no-show non-refundable fee. DiaEdu Management Consultants is not liable for injury, damage, loss, accident, delay or irregularity, additional expense or liability occasioned to any person or property for passengers intended for transportation on the transportation page. All purchased social event passes on the social events page need to be collected onsite at the registration area by providing the unique identification number, as received in the registration confirmation email. Visitors to social events are required to hand over the pass at the entry of the social event in order to be granted access.",
    "terms_and_conditions.description6": "Cancellation for UAE-based registrants one month prior to the event will provide a refund of the sum paid less AED100 in cancellation fees. Cancellation for international registrants one month prior to the event will provide a refund of the sum paid less AED200 in cancellation fees. Cancellation for registrants less than one month before the event is non-refundable. These cancellation and refund conditions also apply to the conference requests for accommodation, transportation and social events. By agreeing to these Terms & Conditions DiaEdu Management Consultants has the right to submit electronic communication to the email address(s) used on the participant page for the purpose of marketing current and future events provided by DiaEdu Management Consultants. These Terms and Conditions shall be governed by and construed in all respects in accordance with the laws of the Emirate of Dubai and such Federal Laws of the UAE as shall have effect in the Emirate of Dubai.",
    "terms_and_conditions.description7": "Any dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of UAE. United Arab of Emirates is our country of domicile. Minors under the age of 18 shall are prohibited to register as a User of this website and are not allowed to transact or use the website.",
    "terms_and_conditions.description8": "If you make a payment for our products or services on our website, the details you are asked to submit will be provided directly to our payment provider via a secured connection. The cardholder must retain a copy of transaction records and Merchant policies and rules. Refunds will be done only through the Original Mode of Payment.",

    "ticket_cart.no_tickets": "There are no tickets in the cart",
    "ticket_cart.proceed_checkout": "Proceed checkout",
    "ticket_cart.payment.success_pay": "You have successfully bought ticket(s).",
    "ticket_cart.payment.check_email": "Check email for details.",
    "ticket_cart.payment.button.back_to_events": "Back to events",
    "ticket_cart.payment.free_ticket": "Free",
    "ticket_cart.payment.btnPay": "Pay",
    "ticket_cart.payment.errorTerms": "You must first accept terms and conditions.",

    "user_profile.title": "User account",
    "user_profile.menu.user_details": "Profile details",
    "user_profile.menu.change_password": "Change password",
    "user_profile.menu.my_purchase": "Purchase history",
    "user_profile.menu.my_downloads": "My downloads",
    "user_profile.menu.set_password": "Set password",
    "user_profile.menu.settings": "Settings",
    "user_profile.user_details.form.user_profile.title": "USER ACCOUNT",
    "user_profile.user_details.form.user_profile.first_name": "First name",
    "user_profile.user_details.form.user_profile.last_name": "Last name",
    "user_profile.user_details.form.user_profile.email": "E-mail",
    "user_profile.user_details.form.user_profile.phone": "Phone number",
    "user_profile.user_details.form.user_profile.update_info": "User information updated.",
    "user_profile.user_details.form.user_profile.update_error": "Problem with updating user account. Please try again later.",
    "user_profile.user_details.form.user_profile.company": "Company",
    "user_profile.user_details.form.change_password.title": "CHANGE PASSWORD",
    "user_profile.user_details.form.change_password.old_password": "Old password",
    "user_profile.user_details.form.change_password.new_password": "New password",
    "user_profile.user_details.form.change_password.confirm_password": "Confirm password",
    "user_profile.user_details.form.change_password.password_match_error": "Confirm password doesn't match.",
    "user_profile.user_details.form.change_password.password_changed": "Password changed.",

    "INVALID_PASSWORD": "Current password is invalid.",
    "INVALID_EMAIL_OR_PASSWORD": "Email or password are invalid."
};

export default en;
