import * as React from "react";
import { Typography, DialogContent, DialogTitle, Dialog, useMediaQuery, Theme } from "@material-ui/core";
import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";
import images from "$assets/images";
import { LoginForm } from "./LoginForm";
import { DialogCloseButton } from "$components/DialogCloseButton";

interface Props {
    handleClose: () => void;
    open: boolean;
}

const Login = (props: Props) => {
    const { handleClose, open } = props;
    const classes = useStyles();
    const literals = useLiterals();
    const isSmallerThenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullScreen={isSmallerThenSm}>
            <DialogTitle id="customized-dialog-title" onClick={handleClose}>
                <img className={classes.logo} src={images.logo} height="50" alt="logo" />
                <Typography variant="h4" component="div" className={classes.title}>
                    {literals("sign_in.title")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <LoginForm useRedirect={false} />
            </DialogContent>
            <DialogCloseButton onClick={handleClose} />
        </Dialog>
    );
};

export { Login };
