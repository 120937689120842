import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.secondary.contrastText,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 30,
            paddingRight: 30,
            textAlign: "center",
            position: "relative",
        },
        closeButton: {
            color: theme.palette.secondary.contrastText,
            position: "absolute",
            right: 0,
            top: 8,
        },
    })
);
