export const SET_CARD_TYPE = "SET_CARD_TYPE" as "SET_CARD_TYPE";
export const SET_CARD_HOLDER = "SET_CARD_HOLDER" as "SET_CARD_HOLDER";
export const SET_CARD_NUMBER = "SET_CARD_NUMBER" as "SET_CARD_NUMBER";
export const SET_EXPIRE_MONTH = "SET_EXPIRE_MONTH" as "SET_EXPIRE_MONTH";
export const SET_EXPIRE_YEAR = "SET_EXPIRE_YEAR" as "SET_EXPIRE_YEAR";
export const SET_CVC = "SET_CVC" as "SET_CVC";
export const SET_SIGNATURE = "SET_SIGNATURE" as "SET_SIGNATURE";
export const SET_FORTH_ID = "SET_FORTH_ID" as "SET_FORTH_ID";
export const RESET = "RESET" as "RESET";
export const SET_TERMS = "SET_TERMS" as "SET_TERMS";

export type PaymentAction =
    | { type: typeof SET_CARD_HOLDER; cardHolder: string }
    | { type: typeof SET_CARD_NUMBER; cardNumber: string }
    | { type: typeof SET_CARD_TYPE; cardType: string }
    | { type: typeof SET_CVC; cvc: string }
    | { type: typeof SET_EXPIRE_MONTH; expireMonth: string }
    | { type: typeof SET_EXPIRE_YEAR; expireYear: string }
    | { type: typeof SET_SIGNATURE; signature: string }
    | { type: typeof SET_FORTH_ID; forthId: string }
    | { type: typeof RESET }
    | { type: typeof SET_TERMS; terms: boolean };
