import { createTheme } from "@material-ui/core/styles";
import { defaultStyle } from "./default";

export const classic = createTheme({
    ...defaultStyle,
    palette: {
        primary: {
            light: "#FF947F",
            main: "#FF7251",
            dark: "#E56649",
            contrastText: "#ffffff",
        },
        secondary: {
            light: "#F5F5F5",
            main: "#000000",
            dark: "#000000",
            contrastText: "#ffffff",
        },
        text: {
            primary: "#3F3F3F",
        },
        background: {
            default: "#ffffff",
        },
    },
});