import React from "react";
import TotalInformation from "./TotalInformation/TotalInformation";

export interface IPaymentProps {
    eventId: string;
    cartId: string;
}

export const Payment = (props: IPaymentProps) => {

    return (
        <TotalInformation {...props} />
    );
};
