export enum Pages {
    Events = 0,
    SignUp,
    TermsAndConditions,
    ContactUs,
    Profile,
}

interface IPagesRoute {
    Events: string;
    SignUp: string;
    TermsAndConditions: string;
    ContactUs: string;
    Profile: string;
}

export const PagesRoute: IPagesRoute = {
    Events: "/events",
    SignUp: "/sign-up",
    TermsAndConditions: "/terms-and-conditions",
    ContactUs: "/contact-us",
    Profile: "/profile",
};
