import * as React from "react";

import { CircularProgress, makeStyles, Theme, createStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: "relative",
            width: "100%",
            height: "100%",
            minHeight: 300,
        },
        loader: ({ size }: { size: number }) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -size / 2,
            marginLeft: -size / 2,
        }),
    })
);

const sizes = {
    xs: 16,
    sm: 24,
    md: 48,
    lg: 64,
};

interface ILoaderProps {
    loading: boolean;
    size?: "xs" | "sm" | "md" | "lg";
    children?: JSX.Element[];
}

const Loader = (props: ILoaderProps) => {
    const { loading, size, children } = props;
    const classes = useStyles({ size: sizes[size || "md"] });
    return (
        <div className={classes.wrapper}>
            {children}
            {loading && <CircularProgress size={sizes[size || "md"]} className={clsx([classes.loader])} />}
        </div>
    );
};

export { Loader };
