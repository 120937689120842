/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import _reduce from "lodash/reduce";
import { useParams, useHistory } from "react-router-dom";

import { AttendeeWithRedux, Payment } from "$components/core";

import { ticketCartService } from "$services/core";

import { EventDTO, AttendeeDTO } from "@djordjeandjelkovic/medgress_common_react_modules";

import { useCheckoutSelectors, useAttendeeSelector, useTicketCartSelector } from "$redux/selectors";

import { usePrevious } from "$helper/usePrevious";

import { useLiterals } from "$hooks/Translate/useLiterals";
import { IState } from "./IState";
import { IActions } from "./IActions";
import { useMessage } from "$helper/useMessage";
import { removeActiveStepFromLocalStorage, setActiveStepToLocalStorage } from "./checkoutHelper";
import { useCheckoutActionCreators } from "./useCheckoutActionsCreators";
import { useFetchEventDetails } from "./useFetchEventDetails";
import { useValidations } from "./useValidations";
import { useActiveStep } from "./usaActiveStep";
import { useRemoveTicket } from "./useRemoveTicket";
import { TicketCart } from "$models/types";
import { authenticationService, userService } from "$services/core";

const useCheckoutState = (): [IState, IActions] => {
    const { eventId } = useParams<{ eventId: string }>();
    const history = useHistory();
    const { showError } = useMessage();
    const getLiteral = useLiterals();
    const { validate } = useValidations({ eventId });

    const [loading, setLoading] = useState<boolean>(false);

    const { numberOfTickets, tickets } = useCheckoutSelectors(eventId!);
    const { activeStep, isAttendeeStep, setCurrentStep, stepperOptions, hasAttendeeSteps } = useActiveStep({
        eventId,
    });
    const { attendee } = useAttendeeSelector(eventId, activeStep.index!);
    const { cartId } = useTicketCartSelector(eventId);
    const { setActiveStep, setAttendeeId, removeAllTickets, setTerms } = useCheckoutActionCreators();

    const [eventDetails, setEventDetails] = useState<EventDTO | undefined>(undefined);
    const fetchEventDetails = useFetchEventDetails(setEventDetails);

    const redirectToHomePage = !hasAttendeeSteps;

    const previous = usePrevious(redirectToHomePage ? undefined : "checkout", { eventId });

    const removeTicket = useRemoveTicket({ eventId, cartId });

    const total = calculateTotal(tickets);
    const referenceToCheckoutForm = useRef<HTMLDivElement>(null);

    const [companyId, setCompanyId] = useState<string>();

    useEffect(() => {
        redirectToHomePage && removeActiveStepFromLocalStorage();
    }, [redirectToHomePage]);

    useEffect(() => {
        if (previous?.eventId !== eventId) {
            setActiveStepToLocalStorage(stepperOptions[0]);
        }
        fetchEventDetails.fetch(eventId);
    }, [eventId]);

    useEffect(() => {
        const user = authenticationService.getUser();
        if (!user) return;

        const getData = async () => {
            try {
                const user = await userService.getUser();
                setCompanyId(user.companyId!);
            } catch (error) {}
        };

        getData();
    }, [setCompanyId]);

    useEffect(() => {
        if (!cartId) {
            return;
        }

        setCurrentStep(previous);
    }, [stepperOptions, eventId, cartId]);

    const getStepValue = (increment: number) => {
        return activeStep.index! + increment === -1
            ? stepperOptions[0]
            : activeStep.index! + increment === stepperOptions.length
            ? stepperOptions[stepperOptions.length - 1]
            : stepperOptions[activeStep.index! + increment];
    };

    const goBack = () => {
        const nextStepValue = getStepValue(-1);
        setActiveStepToLocalStorage(nextStepValue);
        setActiveStep(nextStepValue);
        setTerms(false);
    };

    const scrollToElement = () => {
        referenceToCheckoutForm?.current?.scrollIntoView();
    };

    const goNext = async () => {
        if (!validate(activeStep.component)) {
            showError(getLiteral(`checkout.steps.error_in_validation`));
            scrollToElement();
            return;
        }

        try {
            setLoading(true);
            await tryToAddAttendee();
            const nextStepValue = getStepValue(1);
            setActiveStepToLocalStorage(nextStepValue);
            setActiveStep(nextStepValue);
        } catch (error) {
            showError(getLiteral(`engine.response.${error}`));
            console.error(error, "Problem with creating/updating attendee.");
        } finally {
            setLoading(false);
        }

        if (activeStep.component === Payment) {
            removeAllTickets(eventId!);
            removeActiveStepFromLocalStorage();
            history.push("/");
        }
    };

    const handleRemoveTicket = async (id: string) => {
        await removeTicket.remove(id);
    };

    const tryToAddAttendee = async () => {
        if (cartId && activeStep.component === AttendeeWithRedux) {
            const attendeeResponse: AttendeeDTO = await ticketCartService.addAttendeeToCart(
                cartId,
                eventId!,
                activeStep.priceId!,
                activeStep.ticketId!,
                attendee!
            );

            setAttendeeId(activeStep.index!, eventId!, attendeeResponse.id!);
            return;
        }
    };

    const stepperLabels = stepperOptions.map((option, index) => ({
        label: option.label!,
        icon: option.labelComponent!,
        index,
        id: `${option.label!}${index}`,
    }));

    return [
        {
            eventId,
            cartId: cartId ? cartId : undefined,
            numberOfTickets,
            activeStep,
            loading,
            stepperLabels,
            redirect: redirectToHomePage,
            eventDetails,
            isAttendeeStep,
            total,
            referenceToCheckoutForm,
            companyId,
        },
        { goBack, goNext, handleRemoveTicket },
    ];
};

const calculateTotal = (tickets?: TicketCart[]) => {
    if (!tickets) return 0;
    return _reduce(tickets, (sum, t) => sum + (t.ticket.price?.amount || 0), 0);
};

export { useCheckoutState };
