import React from "react";

import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import { engineUrl } from "$helper/api";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { ViewTicketPDFProps } from "../Models";

import useViewTicketPDFState from "./useViewTicketPDFState";
import { useStyles } from "../styles";

const PAGE_NUMBER = 1;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default (props: ViewTicketPDFProps) => {
    const { show, eventId, attendeeId, onClose } = props;
    const [{
        pdfWidth
    }, {
        increasePdfWidth, reducePdfWidth
    }] = useViewTicketPDFState();
    const classes = useStyles();

    return (
        <Dialog maxWidth="md" fullWidth open={show} onClose={onClose}>
            <DialogContent>
                <Document
                    file={{
                        url: `${engineUrl()}/ticket-carts/download/events/${eventId}/attendees/${attendeeId}`,
                    }}>
                    <Page className={classes.pdfPage} pageNumber={PAGE_NUMBER} renderMode="svg" width={pdfWidth} />
                </Document>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={increasePdfWidth}>
                    Zoom In
                </Button>
                <Button color="primary" variant="outlined" onClick={reducePdfWidth}>
                    Zoom Out
                </Button>
                <Button color="primary" variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
