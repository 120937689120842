import * as React from "react";
import _find from "lodash/find";
import _map from "lodash/map";

import { TextField, DropDownListField } from "@djordjeandjelkovic/medgress_common_react_modules";
import { Grid } from "@material-ui/core";
import { useStyles } from "../styles";
import { useCreditCardInfoState, cardTypes } from "./creditCardInfoState";


const CreditCardInfo = () => {
    const [{ cardNumber, expireMonth, expireYear, cvc, cardType, cardHolder }, { handleChange }] =
        useCreditCardInfoState();
    const classes = useStyles();

    const card = _find(cardTypes, (type) => type.name === cardType);
    const cardNumberLength = card?.constraints.cardNumberLength;
    const cardCvcLength = card?.constraints.cvcLength;

    const isCardTypeSet: boolean = cardType !== "";

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <DropDownListField
                    fullWidth
                    name="cardType"
                    label="Pay method"
                    value={cardType}
                    options={_map(cardTypes, (type) => ({ name: type.name, value: type.name }))}
                    onChange={handleChange}
                    TextFieldProps={{
                        variant: "outlined" as "outlined",
                        InputLabelProps: { shrink: true },
                        placeholder: "Card type",
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    disabled={!isCardTypeSet}
                    fullWidth
                    required
                    name="cardHolder"
                    constraints={{ maxLength: 50 }}
                    label="Card holder"
                    value={cardHolder}
                    onChange={handleChange}
                    TextFieldProps={{ variant: "outlined", InputLabelProps: { shrink: true } }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    disabled={!isCardTypeSet}
                    fullWidth
                    required
                    name="cardNumber"
                    label="Card number"
                    type="number"
                    constraints={{ maxLength: cardNumberLength }}
                    value={cardNumber}
                    onChange={handleChange}
                    TextFieldProps={{ variant: "outlined", InputLabelProps: { shrink: true } }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    disabled={!isCardTypeSet}
                    required
                    name="expireMonth"
                    label="MM"
                    type="number"
                    constraints={{ maxLength: 2 }}
                    value={expireMonth}
                    onChange={handleChange}
                    TextFieldProps={{
                        className: classes.expireDate,
                        variant: "outlined",
                        InputLabelProps: { shrink: true },
                        inputProps: {
                            maxLength: 2,
                        },
                        onBlur: (event) =>
                            handleChange({
                                target: { value: event.target.value, name: "expireMonth" },
                                type: "blur",
                            }),
                    }}
                />
                <TextField
                    disabled={!isCardTypeSet}
                    required
                    name="expireYear"
                    label="YY"
                    type="number"
                    constraints={{ maxLength: 2 }}
                    value={expireYear}
                    onChange={handleChange}
                    TextFieldProps={{
                        className: classes.expireDate,
                        variant: "outlined",
                        InputLabelProps: { shrink: true },
                        inputProps: {
                            maxLength: 2,
                        },
                        onBlur: (event) =>
                            handleChange({
                                target: { value: event.target.value, name: "expireYear" },
                                type: "blur",
                            }),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    disabled={!isCardTypeSet}
                    fullWidth
                    required
                    name="cvc"
                    label="CVC"
                    type="number"
                    constraints={{ maxLength: cardCvcLength }}
                    value={cvc}
                    onChange={handleChange}
                    TextFieldProps={{ variant: "outlined", InputLabelProps: { shrink: true } }}
                />
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
        </Grid>
    );
};

export { CreditCardInfo };
