import { createTheme } from "@material-ui/core/styles";
import { defaultStyle } from "./default";

export const dark = createTheme({
    ...defaultStyle,
    palette: {
        type: "dark",
        primary: {
            light: "#FF947F",
            main: "#FF7251",
            dark: "#E56649",
            contrastText: "#ffffff",
        },
        secondary: {
            light: "#727272",
            main: "#000000",
            dark: "#000000",
            contrastText: "#ffffff",
        },
        background: {
            default: "#303030",
        },
    },
});
