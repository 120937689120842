import { useTheme, makeStyles } from "@material-ui/core";

const styles = makeStyles((theme: any) => ({
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}));

const useStyles = () => {
    const theme = useTheme();
    const classes = styles(theme);

    return classes;
};

export { useStyles };
