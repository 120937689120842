import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { GridProps } from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PaymentIcon from "@material-ui/icons/Payment";

import { CreditCardInfo } from "$components/Payment/CreditCardInfo/CreditCardInfo";
import { usePaymentHandler } from "$components/Payment/CreditCardInfo/usePaymentHandler";
import { TopBorderContainer } from "$components/core";

import { ApiErrors, SendTicketsTo } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";
import images from "$assets/images";
import { useMessage } from "$helper/useMessage";
import { useCreditCardInfoState } from "$components/Payment/CreditCardInfo/creditCardInfoState";
import { usePaymentSelectors } from "$redux/selectors/usePaymentSelectors";

import { useStyles } from "../styles";
import { getPaymentHandler } from "$services/company.service";
import { PayFortPayment } from "$components/Payment/PayFortPayment/PayFortPayment";

type PaymentGridProps = {
    visible?: boolean;
    cartId: string;
    eventId: string;
    companyId: string;
} & GridProps;
export const PaymentGrid = (props: PaymentGridProps) => {
    const { cartId, eventId, visible, companyId, ...rest } = props;
    const classes = useStyles({});
    const literals = useLiterals();
    const { showError } = useMessage();
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const { payment } = usePaymentSelectors();
    const [paymentHandler, setPaymentHandler] = useState<string>("");
    const [sendTicketsTo, setSendTicketsTo] = useState(SendTicketsTo.ALL);
    const [, creditCardState] = useCreditCardInfoState();

    useEffect(() => {
        const getData = async () => {
            if (!companyId) return;
            const ph = await getPaymentHandler(companyId);
            setPaymentHandler(ph);
        };
        getData();
    }, [companyId]);

    const paymentService = usePaymentHandler(paymentHandler);

    if (!visible) return null;

    if (isSuccess) {
        return <Redirect to={{ pathname: "/success-payment", state: { eventId } }} />;
    }

    const handlePay = async () => {
        if (!payment.terms) {
            showError("You must accept the terms and conditions.");
            return;
        }
        try {
            setLoading(true);
            await paymentService?.pay(cartId, payment, sendTicketsTo);
            setLoading(false);
            setSuccess(true);
            creditCardState.reset();
        } catch (error) {
            setLoading(false);
            console.error(error);
            showError(ApiErrors[error as string]);
        }
    };

    let p = <></>;
    switch (paymentHandler) {
        case "payfort":
            p = (
                <>
                    <AdditionalServices value={sendTicketsTo} onChange={(value) => setSendTicketsTo(value)} />
                    <PayFortPayment eventId={eventId} cartId={cartId} ticketsTo={sendTicketsTo} />
                </>
            );
            break;
        case "stripe":
            p = (
                <>
                    <Typography variant="h5" className={classes.paymentTitle}>
                        Select payment
                    </Typography>
                    <VisaMasterCardAccordion
                        paymentHandler={paymentHandler}
                        onPaymentHandler={setPaymentHandler}
                    />
                    <AdditionalServices value={sendTicketsTo} onChange={(value) => setSendTicketsTo(value)} />
                    <Button
                        color="primary"
                        className={classes.paymentButton}
                        fullWidth
                        variant="contained"
                        onClick={handlePay}
                        disabled={!payment.terms || isLoading}>
                        {isLoading && <CircularProgress className={classes.paymentProgress} size={24} />}
                        <PaymentIcon />
                        {literals("ticket_cart.payment.btnPay")}
                    </Button>
                </>
            );
            break;
    }

    return (
        <Grid {...rest}>
            <TopBorderContainer className={classes.wrapper}>{p}</TopBorderContainer>
        </Grid>
    );
};

type AdditionalServicesProps = {
    onChange: (value: SendTicketsTo) => void;
    value: SendTicketsTo;
};
const AdditionalServices = (props: AdditionalServicesProps) => {
    const { onChange, value } = props;
    const classes = useStyles({});
    return (
        <div className={classes.additionalServices}>
            <Typography variant="h6">Additional services</Typography>
            <FormControlLabel
                label="Send tickets to all attendees"
                control={
                    <Radio
                        color="primary"
                        checked={value === SendTicketsTo.ALL}
                        onClick={() => onChange(SendTicketsTo.ALL)}
                    />
                }
            />
            <FormControlLabel
                label="Send all tickets to me"
                control={
                    <Radio
                        color="primary"
                        checked={value === SendTicketsTo.ME}
                        onClick={() => onChange(SendTicketsTo.ME)}
                    />
                }
            />
        </div>
    );
};

// type PaypalAccordionProps = {
//     paymentHandler: string;
//     onPaymentHandler: React.Dispatch<React.SetStateAction<string>>;
// };
// const PaypalAccordion = ({ paymentHandler, onPaymentHandler }: PaypalAccordionProps) => {
//     const classes = useStyles({});

//     return (
//         <Accordion expanded={paymentHandler === "paypal"} onChange={() => onPaymentHandler("paypal")}>
//             <AccordionSummary>
//                 <Radio checked={paymentHandler === "paypal"} color="primary" />
//                 <Typography variant="subtitle1" className={classes.paymentAccordionTitle}>
//                     Paypal
//                 </Typography>
//                 <img
//                     className={classes.paymentAccordionImg}
//                     src={images.paypal}
//                     alt="paypal"
//                     width={90}
//                     height={30}
//                 />
//             </AccordionSummary>
//             <AccordionDetails>
//                 <CreditCardInfo />
//             </AccordionDetails>
//         </Accordion>
//     );
// };

type VisaMasterCardAccordionProps = {
    paymentHandler: string;
    onPaymentHandler: React.Dispatch<React.SetStateAction<string>>;
};
const VisaMasterCardAccordion = ({ paymentHandler, onPaymentHandler }: VisaMasterCardAccordionProps) => {
    const classes = useStyles({});
    return (
        <Accordion expanded={paymentHandler === "stripe"} onChange={() => onPaymentHandler("stripe")}>
            <AccordionSummary>
                <Radio checked={paymentHandler === "stripe"} color="primary" />
                <Typography variant="subtitle1" className={classes.paymentAccordionTitle}>
                    Credit card
                </Typography>
                <div>
                    <img
                        className={classes.paymentAccordionImg}
                        src={images.creditCard}
                        alt="visamastercard"
                        width={110}
                        height={30}
                    />
                </div>
            </AccordionSummary>

            <AccordionDetails>
                <CreditCardInfo />
            </AccordionDetails>
        </Accordion>
    );
};
