import React from "react";
import { authenticationService } from "$services/core";
import { Avatar } from "@material-ui/core";
import { getInitials } from "$pages/UserProfile/UserActions";
import { useStyles } from "./styles";

export const UserProfileAvatar = () => {
    const classes = useStyles();
    const user = authenticationService.getUser();
    if (!user) {
        return null;
    }

    return (
        <div className={classes.userProfileAvatarContainer}>
            <Avatar className={classes.userProfileAvatar}>{getInitials(user)}</Avatar>
            <span className={classes.userProfileAvatarTitle}>{user?.firstName + " " + user?.lastName}</span>
        </div>
    );
};
