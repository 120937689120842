import React from "react";

type ActionCreator<A = any> = (...args: any[]) => A;

interface ActionCreatorMapper<A = any> {
    [key: string]: ActionCreator<A>;
}

export function bindActionCreators<A = any>(
    creators: ActionCreatorMapper<A>,
    dispatch: React.Dispatch<A>
): ActionCreatorMapper<A> {
    const obj: ActionCreatorMapper = {};

    const creatorKeys = Object.keys(creators);
    creatorKeys
        .filter((key) => typeof creators[key] === "function")
        .forEach(
            (key: string) =>
                (obj[key] = (...args) => {
                    const temp = creators[key](...args);
                    dispatch(temp);
                })
        );

    return obj;
}
