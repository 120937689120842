import { useLocation } from "react-router";

export const useQuery = () => {
    const query = new URLSearchParams(useLocation().search);

    return {
        d: query.get("d"),
        h: query.get("h"),
    };
};
