import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterShapeIcon: {
            margin: 10,
        },
        filterLine: {
            marginBottom: 30,
        },
    })
);
