import * as React from "react"

import { Container, Paper, Grid, useTheme } from "@material-ui/core"
import ContentLoader from "react-content-loader"

const EventItemLoader = () => {
    const theme = useTheme();

    return (
        <ContentLoader viewBox="0 0 70 74" backgroundColor={theme.palette.grey[400]} foregroundColor={theme.palette.grey[300]}>
            <rect x="1" y="0" rx="1" ry="1" width="68" height="24" />
            <rect x="1" y="25" rx="1" ry="1" width="10" height="5" />
            <rect x="59" y="25" rx="1" ry="1" width="10" height="5" />
            <rect x="1" y="31" rx="1" ry="1" width="68" height="5" />
            <rect x="1" y="37" rx="1" ry="1" width="68" height="22" />
            <rect x="15" y="61" rx="1" ry="1" width="40" height="3" />
            <rect x="1" y="66" rx="1" ry="1" width="68" height="7" />
        </ContentLoader>
    );
}

export const EventItemContainerLoader = (props: EventItemsProps) => {
    return (
        <Grid item lg={4} xs={12}>
            <Paper className={props.classes}>
                <EventItemLoader />
            </Paper>
        </Grid>
    )
}

interface EventItemsProps {
    classes?: string
}

export const EventItemsContainerLoader = (props: EventItemsProps) => {
    return (
        <Container>
            <Grid container spacing={2}>
                <EventItemContainerLoader classes={props.classes} />
                <EventItemContainerLoader classes={props.classes} />
                <EventItemContainerLoader classes={props.classes} />
                <EventItemContainerLoader classes={props.classes} />
                <EventItemContainerLoader classes={props.classes} />
                <EventItemContainerLoader classes={props.classes} />
            </Grid>
        </Container>
    )
}
