import React from "react";
import { Divider, MenuList, MenuItem } from "@material-ui/core";
import { Pages, PagesRoute } from "$constants/Pages";
import { useThemeSelectors } from "$redux/selectors/useThemeSelectors";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as ThemeActionCreators from "$redux/actions/Theme/themeActions";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { NavItem } from "../NavItem";
import { UserProfileAvatar } from "../UserProfile/UserProfileAvatar";
import { LogInOutActions } from "./LogInOutActions";
import { useStyles } from "./styles";
import { authenticationService } from "$services/core";
import images from "$assets/images";
import ReorderIcon from '@material-ui/icons/Reorder';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmailIcon from '@material-ui/icons/Email';
import Brightness2 from "@material-ui/icons/Brightness2";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

export type HamburgerMenuProps = {
    onPageChanged: (currentPage: number) => void;
    onOpenSignIn: (open: boolean) => void;
    onOpenSignUp: (open: boolean) => void;
    onClose: () => void;
};

export const HamburgerMenu = (props: HamburgerMenuProps) => {
    const { onPageChanged, onOpenSignIn, onOpenSignUp, onClose } = props;

    const getLiteral = useLiterals();
    const dispatch = useDispatch();
    const { isClassicTheme } = useThemeSelectors();  
    const classes = useStyles({isClassicTheme});
      
    const { setIsClassicTheme } = bindActionCreators(ThemeActionCreators, dispatch);
    const isLoggedIn = authenticationService.isLoggedIn();

    const user = authenticationService.getUser();

    return (
        <>
            <MenuList className={classes.sideMenuContainer}>
                {
                    isLoggedIn ? 
                    <MenuItem component={NavLink} to={`/users/${user?.id}`} onClick={() => onPageChanged(Pages.Profile)}>
                        <UserProfileAvatar />
                    </MenuItem> 
                    : 
                    <MenuItem component={NavLink} to={"/"} onClick={() => onPageChanged(Pages.Events)}>
                        <img className={classes.sideMenuLogo} src={images.logo} height="40" alt="logo" />
                    </MenuItem>
                }
                
                <Divider />
                <MenuItem>
                    <ReorderIcon className={classes.sideMenuItem} />
                    <NavItem
                        to={PagesRoute.Events}
                        title={getLiteral("header.menu.events")}
                        page={Pages.Events}
                        onClick={onPageChanged}
                    />
                </MenuItem>
                <MenuItem>
                    <DoneAllIcon className={classes.sideMenuItem} />
                    <NavItem
                        to={PagesRoute.TermsAndConditions}
                        title={getLiteral("header.menu.terms_and_conditions")}
                        page={Pages.TermsAndConditions}
                        onClick={onPageChanged}
                    />
                </MenuItem>
                <MenuItem>
                    <EmailIcon className={classes.sideMenuItem} />
                    <NavItem
                        to={PagesRoute.ContactUs}
                        title={getLiteral("header.menu.contact_us")}
                        page={Pages.ContactUs}
                        onClick={onPageChanged}
                    />
                </MenuItem>
                    
                <Divider />

                <LogInOutActions onOpenSignUp={onOpenSignUp} onOpenSignIn={onOpenSignIn} onClose={onClose} />

                <Divider />

                <MenuItem onClick={() => setIsClassicTheme(!isClassicTheme)}>
                    <Brightness2 className={clsx(classes.theme, classes.sideMenuItem)} />
                    DARK THEME
                </MenuItem>
            </MenuList>
        </>
    );
};
