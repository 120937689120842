import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(2),
        },
        title: {
            textAlign: "center",
            padding: 20,
        },
        purchaseHistory: {
            float: "right",
            margin: 10,
        },
        profileInfoContainer: {
            margin: theme.spacing(2),
        },
        profileInfo: {
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
        },
        profileInfoLogout: {
            marginLeft: -9,
        },
        userPicture: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            margin: "auto",
        },
        userInfoValue: {
            wordBreak: "break-all",
        },
        userName: {
            paddingLeft: 5,
        },
        [theme.breakpoints.down("md")]: {
            userName: {
                textAlign: "center",
            },
        },        
        menuItem: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            borderTop: `0.5px solid ${theme.palette.secondary.light}`,
            cursor: "pointer",
        },
        menuItemArrow: {
            float: "right",
            color: theme.palette.primary.main,
        },
        formContainer: {
            margin: theme.spacing(2),
        },
        cardActions: {
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        },
        updateButton: {
            marginLeft: "auto",
        },
        cardContent: {
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        },
        loading: {
            position: "absolute",
            top: "50%",
            left: "50%",
        },
        loading24: {
            marginTop: -24 / 2,
            marginLeft: -24 / 2,
        },
        loading40: {
            marginTop: -40 / 2,
            marginLeft: -40 / 2,
        },
        link: {
            textDecoration: "none",
            color: theme.palette.text.primary,
        },
    })
);
