import React from "react";
import { DECIMAL_PLACES } from "$constants/Numbers";
import { useTicketsUtils } from "$helper/useTicketsUtils";
import { ITicketCartPopper } from "@djordjeandjelkovic/medgress_common_react_modules";
import { CardHeader, Avatar } from "@material-ui/core";
import _reduce from "lodash/reduce";
import { useCartTimeoutSelector } from "$redux/selectors/useCartTimeoutSelector";
import utils from "$helper/utils";

type CartHeaderProps = {
    item: ITicketCartPopper;
};
export const CartHeader = ({ item }: CartHeaderProps) => {
    return (
        <CardHeader
            avatar={<Avatar src={item.event.icon} />}
            title={utils.endWithThreeDot(item.event.name, 40)}
            subheader={
                <div>
                    <TotalSum item={item} />
                    <ElapsedTime eventId={item.event.id} />
                </div>
            }
        />
    );
};

type ElapsedTimeProps = {
    eventId: string;
};
const ElapsedTime = ({ eventId }: ElapsedTimeProps) => {
    const { cartTimeout } = useCartTimeoutSelector();

    if (!cartTimeout[eventId]) return null;

    return <div>Tickets reserved for: {utils.getStringTime(cartTimeout[eventId].timeout)}</div>;
};


const TotalSum = ({ item }: CartHeaderProps) => {
    const { showTotalSum } = useTicketsUtils();

    const ticketPriceSum = _reduce(item.tickets, (sum, ticket) => sum + (ticket.price?.amount || 0), 0);
    const ticketCurrency = item.tickets[0].currency;

    return <div>{showTotalSum(ticketPriceSum.toFixed(DECIMAL_PLACES), ticketCurrency)}</div>;
};
