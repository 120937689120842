import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _map from "lodash/map";
import _slice from "lodash/slice";
import clsx from "clsx";
import { format } from "date-fns";
import { IPurchaseHistoryDTO, DateFormats, PaymentStatusEnum } from "@djordjeandjelkovic/medgress_common_react_modules";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import { useStyles } from "./styles";
import { ticketCartService } from "$services/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { PageHeader } from "$components/core";
import { useTicketsUtils } from "$helper/useTicketsUtils";
import { DECIMAL_PLACES } from "$constants/Numbers";

const ROWS_PER_PAGE = 10;

const PurchaseHistory = () => {
    const classes = useStyles();
    const history = useHistory();
    const getLiteral = useLiterals();

    const [isLoading, setLoading] = useState(false);
    const [purchases, setPurchases] = useState<IPurchaseHistoryDTO[]>([]);
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        async function fetchHistories() {
            try {
                setLoading(true);
                const data = await ticketCartService.purchaseHistories();
                setPurchases(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchHistories();
    }, []);

    const handleRowClick = (cartId: string, eventId: string) => {
        history.push(`/purchase-histories/carts/${cartId}/details?eventId=${eventId}`);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    if (isLoading)
        return (
            <Paper className={classes.paperHistory}>
                <CircularProgress className={clsx(classes.loading, classes.loading40)} />
            </Paper>
        );

    if (purchases.length === 0)
        return (
            <Paper className={classes.paperHistory}>
                <Typography variant="h5">{getLiteral("history_purchase.empty_purchase")}</Typography>
            </Paper>
        );

    return (
        <>
            <PageHeader title={getLiteral("history_purchase.title")} />
            <Paper className={classes.paperHistory}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {getLiteral("history_purchase.event_name")}
                                </TableCell>
                                <TableCell align="right">
                                    {getLiteral("history_purchase.number_of_tickets")}
                                </TableCell>
                                <TableCell align="right">
                                    {getLiteral("history_purchase.purchase_date")}
                                </TableCell>
                                <TableCell>
                                    {getLiteral("history_purchase.reference_id")}
                                </TableCell>
                                <TableCell align="right">
                                    {getLiteral("history_purchase.payment_id")}
                                </TableCell>
                                <TableCell align="right">
                                    {getLiteral("history_purchase.total")}
                                </TableCell>
                                <TableCell align="right">
                                    {getLiteral("history_purchase.payment_status")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_map(
                                _slice(purchases, page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE),
                                (purchase) => (
                                    <PurchaseHistoryTableRow
                                        key={purchase.ticketCartId}
                                        purchase={purchase}
                                        onClick={handleRowClick}
                                    />
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[]}
                    count={purchases.length}
                    rowsPerPage={ROWS_PER_PAGE}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </Paper>
        </>
    );
};

type PurchaseHistoryTableRowProps = {
    purchase: IPurchaseHistoryDTO;
    onClick: (cartId: string, eventId: string) => void;
};

//TODO: Change purchase date
const PurchaseHistoryTableRow = (props: PurchaseHistoryTableRowProps) => {
    const { purchase, onClick } = props;
    const classes = useStyles();
    const { showTotalSum } = useTicketsUtils();

    return (
        <TableRow
            hover
            className={classes.tableRow}
            onClick={() => onClick(purchase.ticketCartId, purchase.eventId)}>
            <TableCell>
                {purchase.eventName}
            </TableCell>
            <TableCell align="right">
                {purchase.attendeesNumber}
            </TableCell>
            <TableCell align="right">
                {format(purchase.paymentDate, DateFormats.DateDefaultFormat)}
            </TableCell>
            <TableCell>
                {purchase.ticketCartId}
            </TableCell>
            <TableCell align="right">
                {purchase.paymentId}
            </TableCell>
            <TableCell align="right">
                {showTotalSum(purchase.ticketPriceSum.toFixed(DECIMAL_PLACES), purchase.ticketPriceCurrency)}
            </TableCell>
            <TableCell align="right">
                {PaymentStatusEnum[purchase.paymentStatus]}
            </TableCell>
        </TableRow>
    );
};

export { PurchaseHistory };
