import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import _map from "lodash/map";
import { EventFilterDTO } from "$models/EventFilter";
import { DateDefaultFormat } from "$constants/Dates";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Grid, TextField, Button, Typography, Theme, useMediaQuery, Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { Company, DropDownListField, EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";
import { EventTypeEnum } from "$enums/EventTypeEnum";
import { EventNatureTypeEnum } from "$enums/EventNatureTypeEnum";
import { DecorativeLine } from "$components/core";
import clsx from "clsx";
import { useMessage } from "$helper/useMessage";
import { $enum } from "ts-enum-util";

type Props = {
    filter: EventFilterDTO;
    changeName: (name: string) => any;
    changeCity: (city: string) => any;
    changeEventType: (type: string) => void;
    changeNatureType: (type: string) => void;
    changeStartDate: (date: Date) => any;
    clearFilter: () => void;
    company?: Company;
};

const EventsFilter = (props: Props) => {
    const { showError } = useMessage();
    const { filter, company } = props;
    const getLiteral = useLiterals();
    const isLowerThenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const [collapseFilter, setCollapseFilter] = useState(false);
    const classes = useStyles({ mediaSmDown: isLowerThenSmall });

    const changeStartDate = (date: MaterialUiPickersDate) => {
        if (date == null) {
            showError(getLiteral("events.events_filter.error_start_date"));
            return;
        }

        props.changeStartDate(date as Date);
    };

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const eventName = e.currentTarget.value;
        props.changeName(eventName);
    };

    const changeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const city = e.currentTarget.value;
        props.changeCity(city);
    };

    const changeEventType = (event: EventChange<string>) => {
        const category = event.target.value;
        props.changeEventType(category);
    };

    const changeNatureType = (event: EventChange<string>) => {
        const location = event.target.value;
        props.changeNatureType(location);
    };

    const handleToggleFilter = () => {
        setCollapseFilter((collapse) => !collapse);
    };

    const eventTypes = ["-1"].concat($enum(EventTypeEnum).getValues().map(String));
    const natureTypes = ["-1"].concat($enum(EventNatureTypeEnum).getValues().map(String));

    return (
        <Grid className={classes.filterContainer}>
            <Grid className={classes.filterContainerShade}>
                <Grid className={classes.filterContent}>
                    <Grid container className={classes.filterItemsContainer} spacing={0}>
                        {isLowerThenSmall && (
                            <>
                                <Grid item md={2} xs={6} className={classes.clearContainer}>
                                    <Button
                                        color="primary"
                                        variant="text"
                                        size="large"
                                        fullWidth
                                        onClick={handleToggleFilter}>
                                        <FilterListIcon fontSize="large" />
                                    </Button>
                                </Grid>
                                <Grid item md={2} xs={6} className={classes.clearContainer}>
                                    <Button
                                        color="primary"
                                        variant="text"
                                        size="large"
                                        fullWidth
                                        onClick={props.clearFilter}>
                                        <CloseIcon fontSize="large" />
                                    </Button>
                                </Grid>
                            </>
                        )}
                        <Grid
                            container
                            item
                            md={11}
                            xs={12}
                            className={clsx({ [classes.filterValuesContainer]: !isLowerThenSmall })}>
                            <Collapse
                                in={collapseFilter || !isLowerThenSmall}
                                className={classes.collapseFullWidth}
                                classes={{
                                    wrapperInner: clsx({ [classes.collapseWrapperInner]: !isLowerThenSmall }),
                                }}>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        id="eventName"
                                        label={getLiteral("events.events_filter.event_name")}
                                        value={filter.name}
                                        className={classes.filterItem}
                                        onChange={changeName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        id="eventCity"
                                        label={getLiteral("events.events_filter.city")}
                                        value={filter.city}
                                        className={classes.filterItem}
                                        onChange={changeCity}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <DropDownListField
                                        fullWidth
                                        name="eventType"
                                        label={getLiteral("events.events_filter.event_type")}
                                        value={filter.eventType || ""}
                                        options={_map(eventTypes, (type) => ({
                                            name: getLiteral(`events.event_type.${type}`),
                                            value: type,
                                        }))}
                                        onChange={changeEventType}
                                        TextFieldProps={{
                                            variant: "outlined" as "outlined",
                                            className: classes.filterItem,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <DropDownListField
                                        fullWidth
                                        name="locationType"
                                        label={getLiteral("events.events_filter.nature_type")}
                                        value={filter.natureType || ""}
                                        options={_map(natureTypes, (type) => ({
                                            name: getLiteral(`events.location_type.${type}`),
                                            value: type,
                                        }))}
                                        onChange={changeNatureType}
                                        TextFieldProps={{
                                            variant: "outlined" as "outlined",
                                            className: classes.filterItem,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            color="primary"
                                            id="startDate"
                                            label={getLiteral("events.events_filter.date_from")}
                                            value={filter.startDate}
                                            format={DateDefaultFormat}
                                            onChange={(date) => changeStartDate(date)}
                                            className={classes.filterItem}
                                            inputVariant="outlined"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Collapse>
                        </Grid>
                        {!isLowerThenSmall && (
                            <Grid item md={1} xs={12} className={classes.clearContainer}>
                                <Button
                                    color="primary"
                                    variant="text"
                                    fullWidth
                                    size="large"
                                    onClick={props.clearFilter}>
                                    <CloseIcon fontSize="large" />
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                    <Grid className={classes.upcomingEvents}>
                        <Typography variant="h3" className={classes.filterHeader}>
                            <span className={classes.filterHeaderEvents}>{company ? company.name + "`s " : ""}</span>
                            <span>
                                {getLiteral("events.events_filter.upcoming_events_part0")}{" "}
                            </span>
                            <span className={classes.filterHeaderEvents}>
                                {getLiteral("events.events_filter.upcoming_events_part1")}
                            </span>
                        </Typography>
                        <DecorativeLine show={!isLowerThenSmall} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export { EventsFilter };
