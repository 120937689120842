import {
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_EMAIL,
    SET_CONFIRM_PASSWORD,
    SET_PASSWORD,
    SET_PHONE,
    RESET_ERROR,
    SET_VALIDATION_RESULT,
    RESET,
} from "./actionTypes";
import { SignUpFormState } from "./SignUpForm";

export const initState = {
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    email: { value: "", error: "" },
    phoneNumber: { value: "", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
};

export const reducer = (state: SignUpFormState, action: any) => {
    switch (action.type) {
        case SET_FIRST_NAME:
            return {
                ...state,
                firstName: {
                    value: action.payload.firstName,
                    error:
                        typeof action.payload.firstName.error === "undefined"
                            ? ""
                            : action.payload.firstName.error,
                },
            };
        case SET_LAST_NAME:
            return {
                ...state,
                lastName: {
                    value: action.payload.lastName,
                    error:
                        typeof action.payload.lastName.error === "undefined"
                            ? ""
                            : action.payload.lastName.error,
                },
            };
        case SET_EMAIL:
            return {
                ...state,
                email: {
                    value: action.payload.email,
                    error:
                        typeof action.payload.email.error === "undefined" ? "" : action.payload.email.error,
                },
            };
        case SET_PHONE:
            return {
                ...state,
                phoneNumber:
                    typeof action.payload.phone === "undefined"
                        ? undefined
                        : {
                              value: action.payload.phone,
                              error:
                                  typeof action.payload.phone.error === "undefined"
                                      ? ""
                                      : action.payload.phone.error,
                          },
            };
        case SET_PASSWORD:
            return {
                ...state,
                password: {
                    value: action.payload.password,
                    error:
                        typeof action.payload.password.error === "undefined"
                            ? ""
                            : action.payload.password.error,
                },
            };
        case SET_CONFIRM_PASSWORD:
            return {
                ...state,
                confirmPassword: {
                    value: action.payload.confirmPassword,
                    error:
                        typeof action.payload.confirmPassword.error === "undefined"
                            ? ""
                            : action.payload.confirmPassword.error,
                },
            };
        case RESET_ERROR:
            return {
                ...state,
                firstName: { ...state.firstName, error: "" },
                lastName: { ...state.lastName, error: "" },
                phoneNumber: { ...state.phoneNumber, error: "" },
                email: { ...state.email, error: "" },
                password: { ...state.password, error: "" },
                confirmPassword: { ...state.confirmPassword, error: "" },
            };
        case SET_VALIDATION_RESULT:
            return {
                ...state,
                ...action.payload,
            };
        case RESET: {
            return { ...initState };
        }
        default:
            throw new Error("Unknow action type");
    }
};
