import * as React from "react";

import { TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

import { companyService } from "$services/core";
import { Company as CompanyModel, EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";

class Company extends CompanyModel {
    inputValue?: string;
}

interface CompanySelectProps {
    label: string;
    value: CompanyModel;
    error: boolean;
    helperText: string;
    disabled?: boolean;
    onChange: (event: EventChange<Company>) => void;
    name: string;
    TextFieldProps?: TextFieldProps;
}

// export interface ChangeEvent {
//     target: {
//         value: Company;
//         name: string | undefined;
//     };
// }

const filter = createFilterOptions<Company>();

const CompanySelect: React.FC<CompanySelectProps> = ({
    value,
    name,
    onChange,
    label,
    error,
    helperText,
    disabled,
    TextFieldProps,
}) => {
    const [companies, setCompanies] = React.useState<Company[]>([]);

    React.useEffect(() => {
        companyService
            .getList()
            .then((companies) => setCompanies((state: Company[]) => [...state, ...companies]));
    }, [setCompanies]);

    const handleChange = React.useCallback(
        (event: any, newValue: string | Company | null) => {
            if (typeof newValue === "string") {
                setTimeout(() => onChange({ target: { value: new CompanyModel("", newValue), name } }));
                return;
            }

            if (newValue && newValue.inputValue) {
                onChange({ target: { value: new CompanyModel("", newValue.inputValue), name } });
                return;
            }

            newValue && onChange({ target: { value: newValue, name } });
        },
        [name, onChange]
    );

    const filterOptions = React.useCallback((options: Company[], params: any) => {
        const filtered = filter(options, params) as Company[];

        if (params.inputValue !== "") {
            filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
                id: "",
            });
        }

        return filtered;
    }, []);

    const renderInput = React.useCallback(
        (params: any) => (
            <TextField
                {...TextFieldProps}
                {...params}
                fullWidth
                error={error}
                helperText={helperText}
                label={label}
                InputProps={{
                    ...params.InputProps,
                    ...TextFieldProps!.InputProps,
                }}
                inputProps={{
                    ...params.inputProps,
                    ...TextFieldProps!.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                }}
            />
        ),
        [label, error, helperText, TextFieldProps]
    );

    return (
        <>
            <Autocomplete
                freeSolo
                disabled={disabled}
                value={value}
                id="country-select-demo"
                options={companies as Company[]}
                onChange={handleChange}
                autoHighlight
                getOptionLabel={(option) => {
                    if (typeof option === "string") return option;

                    return option.inputValue || option.name;
                }}
                renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
                filterOptions={filterOptions}
                renderInput={renderInput}
            />
        </>
    );
};

export { CompanySelect };
