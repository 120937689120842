import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) =>
    createStyles({
        paperRoot: {
            marginBottom: "15px",
            padding: "5px",
        },

        noTickets: {
            width: 250,
            padding: 15,
            textAlign: "center",
        },
        ticketIcon: {
            position: "relative",
            top: 6,
            marginRight: 5,
        },
    })
);
