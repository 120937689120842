import { Theme, makeStyles, createStyles } from "@material-ui/core";

export const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 30,
            background: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
        },
        header: {
            textAlign: "center",
        },
    })
);
