import H256 from "crypto-js/sha256";
export interface ISignatureParams {
    accessCode: string;
    merchantIdentifier: string;
    merchantReference: string;
    language: string;
    returnUrl: string;
    serviceCommand?: string;
    expiryDate?: number;
    cardNumber?: number;
    cardSecurityCode?: number;
    tokenName?: string;
    cardHolderName?: string;
    amount?: number;
    currency?: string;
    customerEmail?: string;
    customerIp?: string;
    command?: string;
    paymentOption?: string;
    merchantExtra?: string;
}

export class PayForthRequest {
    // serviceCommand?: string; //max: 20, mandatory
    // accessCode?: string; //max: 20, mandatory
    // merchantIdentifier?: string; //max: 20, mandatory
    // merchantReference?: string; //max: 40, mandatory
    // language?: string; //max: 2, mandatory, ar/en
    // expiryDate?: number; //max: 4, mandatory
    // cardNumber?: number; //max: 19, mandatory
    // cardSecurityCode?: number; //max: 4, mandatory
    // signature?: string; //max: 200, mandatory
    // customerEmail?: string; //max: 254, mandatory
    // customerIp?: string; //max: 45, mandatory

    // tokenName?: string; //max: 100, optional
    // cardHolderName?: string; //max: 50, optional
    // rememberMe?: string; //max: 3, optional, YES/NO
    // returnUrl?: string; //max: 400, optional

    static createSigniture(params: ISignatureParams) {
        const requestPhrase = "DiaEdu2020!";

        let request = requestPhrase;

        request += params.accessCode ? `access_code=${params.accessCode}` : "";
        request += params.amount ? `amount=${params.amount}` : "";
        request += params.cardHolderName ? `card_holder_name=${params.cardHolderName}` : "";
        request += params.cardNumber ? `card_number=${params.cardNumber}` : "";
        request += params.cardSecurityCode ? `card_security_code=${params.cardSecurityCode}` : "";
        request += params.command ? `command=${params.command}` : "";
        request += params.currency ? `currency=${params.currency}` : "";
        request += params.customerEmail ? `customer_email=${params.customerEmail}` : "";
        request += params.customerIp ? `customer_ip=${params.customerIp}` : "";
        request += params.expiryDate ? `expire_date=${params.expiryDate}` : "";
        request += params.language ? `language=${params.language}` : "";
        request += params.merchantExtra ? `merchant_extra=${params.merchantExtra}` : "";
        request += params.merchantIdentifier ? `merchant_identifier=${params.merchantIdentifier}` : "";
        request += params.merchantReference ? `merchant_reference=${params.merchantReference}` : "";
        request += params.paymentOption ? `payment_option=${params.paymentOption}` : "";
        request += params.returnUrl ? `return_url=${params.returnUrl}` : "";
        request += params.serviceCommand ? `service_command=${params.serviceCommand}` : "";
        request += params.tokenName ? `token_name=${params.tokenName}` : "";

        request += requestPhrase;

        const sha1 = H256(request);
        return sha1.toString();
    }

    static toPurchaseRequest(
        accessCode: string,
        merchantIdentifier: string,
        merchantReference: string,
        language: string,
        amount: number,
        currency: string,
        expiryDate: number,
        cardNumber: number,
        cardSecurityCode: number,
        customerEmail: string,
        customerIp: string,
        tokenName: string,
        cardHolderName: string,
        paymentOption: string,
        returnUrl: string
    ) {
        // const signature = PayForthRequest.createSigniture(
        //     accessCode,
        //     merchantIdentifier,
        //     merchantReference,
        //     language,
        //     returnUrl,
        //     undefined,
        //     expiryDate,
        //     cardNumber,
        //     cardSecurityCode,
        //     tokenName,
        //     cardHolderName,
        //     amount,
        //     currency,
        //     customerEmail,
        //     customerIp,
        //     "PURCHASE",
        //     paymentOption
        // );
        // const obj: any = {
        //     command: "PURCHASE",
        //     access_code: "DQ22n13pXSSxw8ILadkJ",
        //     merchant_identifier: "PMGvpbwn",
        //     merchant_reference: "00000001",
        //     language: "en",
        //     expiry_date: expiryDate,
        //     card_number: cardNumber,
        //     card_security_code: cardSecurityCode,
        //     signature: signature,
        //     token_name: tokenName,
        //     card_holder_name: cardHolderName,
        //     return_url: returnUrl,
        //     amount,
        //     currency,
        //     customer_email: customerEmail,
        //     customer_id: customerIp,
        // };
        // return obj;
    }
}
