import React from "react";
import { useStyles } from "./styles";
import images from "$assets/images";

type DecorativeLineProsp = {
    show: boolean;
};
export const DecorativeLine = (props: DecorativeLineProsp) => {
    const classes = useStyles({ mediaSmDown: !props.show });

    return (
        <>
            {props.show && <img src={images.line.default} className={classes.filterLine} alt="line" />}
            <img src={images.shape.default} className={classes.filterShapeIcon} alt="shape" height="40" />
            {props.show && <img src={images.line.default} className={classes.filterLine} alt="line" />}
        </>
    );
};
