import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderTop: theme.palette.secondary.light,
            borderStyle: "solid",
        },
        title: {
            padding: theme.spacing(3),
            fontWeight: "bold",
        },
    })
);
