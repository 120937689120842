import React from "react";
import { Button, useMediaQuery, MenuItem } from "@material-ui/core";
import { useStyles } from "./styles";
import { UserProfile } from "../UserProfile";
import clsx from "clsx";
import { HistoryPurchaseMenuItem } from "../UserProfile/HistoryPurchaseMenuItem";
import { LogoutMenuItem } from "../UserProfile/LogoutMenuItem";
import { mediaSelector } from "./Nav";
import { authenticationService } from "$services/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

type ILogInOutActions = {
    onOpenSignUp: (open: boolean) => void;
    onOpenSignIn: (open: boolean) => void;
    onClose?: () => void;
};

export const LogInOutActions = (props: ILogInOutActions) => {
    const media = useMediaQuery(mediaSelector);
    const { onOpenSignUp: handleOpenSignUp, onOpenSignIn: handleOpenSignIn, onClose } = props;
    const classes = useStyles({});
    const getLiteral = useLiterals();

    const isLoggedIn = authenticationService.isLoggedIn();

    if (isLoggedIn) {
        return media ? (
            <>
                <HistoryPurchaseMenuItem onClick={onClose} />
                <LogoutMenuItem onClick={onClose} />
            </>
        ) : (
            <UserProfile />
        );
    }

    return media ? (
        <>
            <MenuItem onClick={() => handleOpenSignIn(true)}>
                <VpnKeyIcon className={classes.sideMenuItem} />
                {getLiteral("common.button.sign_in")}
            </MenuItem>
            <MenuItem onClick={() => handleOpenSignUp(true)}>
                <AccessibilityNewIcon className={classes.sideMenuItem} />
                {getLiteral("common.button.sign_up")}
            </MenuItem>
        </>
    ) : (
        <>
            <Button
                onClick={() => handleOpenSignIn(true)}
                variant="contained"
                color="primary"
                className={clsx({ [classes.signIn]: !media })}>
                {getLiteral("common.button.sign_in")}
            </Button>
            <Button onClick={() => handleOpenSignUp(true)} variant="contained" color="primary">
                {getLiteral("common.button.sign_up")}
            </Button>
        </>
    );
};
