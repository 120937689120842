import React from "react";
import { Nav, Footer, PageContainer } from "$components/core";
import { CartTimeout } from "$components/CartTimeout";
import { Announcements } from "$components/core";

type PageProps = {
    component: React.ComponentType<any>;
};
export const Page = ({ component }: PageProps) => {
    return (
        <PageContainer>
            <CartTimeout />
            <Nav />
            <Announcements />
            {React.createElement(component)}
            <Footer />
        </PageContainer>
    );
};
