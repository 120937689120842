import React from "react";

import { Typography } from "@material-ui/core";
import EventsList from "$components/EventsList";
import { EventsFilter } from "$components/EventsFilter";
import { Subscribe } from "$components/core";

import { useStyles } from "./styles";
import { useCompanyEventsState } from "./useCompanyEventsState";
import { CompanyDetails } from "./CompanyDetails";

const CompanyEvents = () => {    
    const classes = useStyles();
    const [
        { list, filteredList, filter, company, isLoading },
        { changeEventStartDate, changeEventType, changeEventName, changeEventCity, changeEventNatureType, clearFilter }
    ] = useCompanyEventsState();

    const hasEvents = !isLoading && list!.length > 0;

    if (!hasEvents) {
        return (
            <>
                <EventsFilter
                    filter={filter!}
                    changeName={changeEventName!}
                    changeCity={changeEventCity!}
                    changeEventType={changeEventType!}
                    changeNatureType={changeEventNatureType!}
                    changeStartDate={changeEventStartDate!}                    
                    clearFilter={clearFilter!}
                    company={company}
                />

                <Typography variant="h4" className={classes.invalidResponse}>
                    No upcoming events
                </Typography>
            </>
        );
    }

    return (
        <>
            <EventsFilter
                filter={filter!}
                changeName={changeEventName!}
                changeCity={changeEventCity!}
                changeEventType={changeEventType!}
                changeNatureType={changeEventNatureType!}
                changeStartDate={changeEventStartDate!}
                clearFilter={clearFilter!}
                company={company}
            />

            <CompanyDetails company={company} />

            <EventsList list={filteredList} />
            <Subscribe />
        </>
    );
};

export { CompanyEvents };
