import React from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { EventChange } from "@djordjeandjelkovic/medgress_common_react_modules";
import * as AttendeeActionCreators from "$redux/actions/AttendeeFields/attendeeFieldsActions";
import { useAttendeeSelector } from "$redux/selectors";
import { Attendee } from "./Attendee";

export type AttendeeProps = {
    eventId: string;
    index: number;
    attendeeType: number;
};

const AttendeeWithRedux = (props: AttendeeProps) => {
    const { eventId, index, attendeeType } = props;
    const dispatch = useDispatch();
    const { attendeeModel } = useAttendeeSelector(eventId, index);

    const { onChange } = bindActionCreators(AttendeeActionCreators, dispatch);

    const handleChange = React.useCallback(
        (event: EventChange<string>) => {
            onChange(index, eventId, event.target.name, event.target.value as string);
        },
        [onChange, index, eventId]
    );

    return <Attendee attendee={attendeeModel} attendeeType={attendeeType} onChange={handleChange} />;
};

export { AttendeeWithRedux };
