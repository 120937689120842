import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { PrivateRoute } from "$components/core";

import { ThemeProvider } from "@material-ui/core/styles";

import { SnackbarProvider } from "notistack";

import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "$redux/store/";

import { Events } from "$pages/Events";
import { EventDetails } from "$pages/EventDetails";
import { EventDetailsPreview } from "$pages/EventDetailsPreview";
import { ActivateAccount } from "$pages/ActivateAccount";
import { Checkout } from "$pages/Checkout";
import TermsAndConditions from "$pages/TermsAndConditions/TermsAndConditions";
import { ContactUs } from "$pages/ContactUs";
import { UserProfile } from "$pages/UserProfile";
import { PurchaseHistory } from "$pages/PurchaseHistory";
import { PurchaseHistoryDetails } from "$pages/PurchaseHistory/PurchaseHistoryDetails";
import { Login } from "$pages/Login";
import { CompanyEvents } from "$pages/CompanyEvents/CompanyEvents";
import { UpdateAttendee } from "$pages/UpdateAttendee";

import { classic, dark } from "$assets/themes";
import CssBaseline from "@material-ui/core/CssBaseline";

import { useThemeSelectors } from "$redux/selectors";
import { ScrollToTop } from "$components/core";
import { RegistrationComplete } from "$components/SignUp/RegistrationComplete/RegistrationComplete";
import { SuccessPayment } from "$components/Payment/SuccessPayment/SuccessPayment";
import { EmbededEventInformation } from "./embeded/EmbededEventInformation";
import { useVersion } from "./useVersion";
import { Page } from "./Page";

export const App = () => {
    const isClassicTheme = useThemeSelectors().isClassicTheme;
    useVersion();

    return (
        <>
            <ThemeProvider theme={isClassicTheme ? classic : dark}>
                <CssBaseline />
                <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    <BrowserRouter>
                        <PersistGate loading={null} persistor={persistor}>
                            <ScrollToTop />
                            <Switch>
                                <Route path="/login" render={() => <Page component={Login} />} />
                                <PrivateRoute
                                    path="/checkout/event/:eventId"
                                    render={() => <Page component={Checkout} />}
                                />
                                <Route
                                    path="/events/:acronym/preview"
                                    render={() => <Page component={EventDetailsPreview} />}
                                />
                                <Route
                                    path="/events/:acronym"
                                    render={() => <Page component={EventDetails} />}
                                />
                                <Route path="/events" render={() => <Page component={Events} />} />
                                <Route
                                    path="/companies/:acronym"
                                    render={() => <Page component={CompanyEvents} />}
                                />
                                <Route
                                    path="/users/activate"
                                    render={() => <Page component={ActivateAccount} />}
                                />
                                <Route
                                    path="/profile"
                                    render={() => <Page component={UserProfile} />}
                                />
                                <Route
                                    path="/purchase-histories/carts/:cartId/details"
                                    render={() => <Page component={PurchaseHistoryDetails} />}
                                />
                                <Route
                                    path="/purchase-histories"
                                    render={() => <Page component={PurchaseHistory} />}
                                />
                                <Route
                                    path="/terms-and-conditions"
                                    render={() => <Page component={TermsAndConditions} />}
                                />
                                <Route path="/contact-us" render={() => <Page component={ContactUs} />} />
                                <Route
                                    path="/sign-up/registration-complete"
                                    render={() => <Page component={RegistrationComplete} />}
                                />
                                <PrivateRoute
                                    path="/success-payment"
                                    render={() => <Page component={SuccessPayment} />}
                                />
                                <Route
                                    path="/embeded/events/:acronym/:size"
                                    component={EmbededEventInformation}
                                />
                                <Route path="/attendees/:attendeeId" component={UpdateAttendee} />
                                <Route path="" exact render={() => <Page component={Events} />} />
                            </Switch>
                        </PersistGate>
                    </BrowserRouter>
                </SnackbarProvider>
            </ThemeProvider>
        </>
    );
};
