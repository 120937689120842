import React, { MouseEvent, useState } from "react";
import {
    Grid,
    IconButton,
    AppBar,
    Toolbar,
    Container,
    useMediaQuery,
    Theme,
    Drawer,
} from "@material-ui/core";
import Brightness2 from "@material-ui/icons/Brightness2";
import MenuIcon from "@material-ui/icons/Menu";
import { TicketCartPopper } from "$components/TicketsCart/TicketCartPopper";
import { useStyles } from "./styles";
import { Pages, PagesRoute } from "$constants/Pages";
import images from "$assets/images";
import { useThemeSelectors } from "$redux/selectors/useThemeSelectors";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as ThemeActionCreators from "$redux/actions/Theme/themeActions";
import { SignUp } from "../../SignUp";
import { Login } from "../../Login";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { NavItem } from "../NavItem";
import { HamburgerMenu } from "./HamburgerMenu";
import { LogInOutActions } from "./LogInOutActions";
import { CartBadge } from "./CartBadge";

export const mediaSelector = (theme: Theme) => theme.breakpoints.down("sm");

const Nav = () => {
    const getLiteral = useLiterals();
    const media = useMediaQuery(mediaSelector);
    const [, setCurrentPage] = useState(Pages.Events);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [openSignUp, setOpenSignUp] = useState<boolean>(false);
    const [openSignIn, setOpenSignIn] = useState<boolean>(false);
    const [openSideBar, setOpenSideBar] = React.useState<boolean>(false);

    const { isClassicTheme } = useThemeSelectors();
    const dispatch = useDispatch();
    const { setIsClassicTheme } = bindActionCreators(ThemeActionCreators, dispatch);

    const classes = useStyles({ isClassicTheme, mediaSmDown: media });

    const handlePageChanged = (currentPage: number) => {
        setCurrentPage(currentPage);
        setOpenSideBar(false);
    };

    const handleCartClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleCartClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="sticky" color="default">
            <Drawer anchor="left" open={openSideBar} onClose={() => setOpenSideBar(false)}>
                <HamburgerMenu
                    onPageChanged={handlePageChanged}
                    onClose={() => setOpenSideBar(false)}
                    onOpenSignIn={(open) => setOpenSignIn(open)}
                    onOpenSignUp={(open) => setOpenSignUp(open)}
                />
            </Drawer>
            <Toolbar>
                <Container>
                    <Grid container className={classes.navContainer} alignItems={"center"}>
                        {!media && (
                            <Grid item md={3} xs={2}>
                                <a href="/">
                                    <img src={images.logo} height="50" alt="logo" />
                                </a>
                            </Grid>
                        )}

                        <Grid item md={6} xs={5} className={classes.menuContainer}>
                            {media && (
                                <IconButton
                                    onClick={() => {
                                        setOpenSideBar(true);
                                    }}>
                                    <MenuIcon />
                                </IconButton>
                            )}

                            {!media && (
                                <ul className={classes.menu}>
                                    <NavItem
                                        to={PagesRoute.Events}
                                        title={getLiteral("header.menu.events")}
                                        page={Pages.Events}
                                        onClick={handlePageChanged}
                                    />
                                    <NavItem
                                        to={PagesRoute.TermsAndConditions}
                                        title={getLiteral("header.menu.terms_and_conditions")}
                                        page={Pages.TermsAndConditions}
                                        onClick={handlePageChanged}
                                    />
                                    <NavItem
                                        to={PagesRoute.ContactUs}
                                        title={getLiteral("header.menu.contact_us")}
                                        page={Pages.ContactUs}
                                        onClick={handlePageChanged}
                                    />
                                </ul>
                            )}
                        </Grid>

                        <Grid item md={3} xs={7} className={classes.loginContainer}>
                            {!media && (
                                <IconButton
                                    className={classes.theme}
                                    onClick={() => setIsClassicTheme(!isClassicTheme)}>
                                    <Brightness2 />
                                </IconButton>
                            )}
                            <CartBadge onClick={handleCartClick} />
                            {!media && (
                                <LogInOutActions onOpenSignUp={setOpenSignUp} onOpenSignIn={setOpenSignIn} />
                            )}
                        </Grid>
                    </Grid>
                    <TicketCartPopper
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCartClose}
                    />
                    <SignUp open={openSignUp} onClose={() => setOpenSignUp((signUp) => !signUp)} />
                    <Login open={openSignIn} handleClose={() => setOpenSignIn((signIn) => !signIn)} />
                </Container>
            </Toolbar>
        </AppBar>
    );
};



export { Nav, Pages };
