import { RESET, CHECKOUT, SET_ACTIVE_STEP } from "./checkoutActionTypes";
import { IStep } from "$pages/Checkout/IStep";

export const reset = () => ({
    type: RESET,
    altType: CHECKOUT,
});

export const setActiveStep = (step: IStep) => ({
    type: SET_ACTIVE_STEP,
    altType: CHECKOUT,
    step,
});
