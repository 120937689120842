import * as React from "react";

import { Grid, Typography, TextField, Button } from "@material-ui/core";

import { useStyles } from "./styles";
import { useLiterals } from "$hooks/Translate/useLiterals";

import { useMediaQuery } from "@material-ui/core";
import { mediaSelector } from "../NavBar";
import { isValidEmail, Subscription } from "@djordjeandjelkovic/medgress_common_react_modules";
import { ChangeEvent, useState } from "react";
import { subscriptionService } from "$services/core";
import { useMessage } from "$helper/useMessage";

const Subscribe = () => {
    const media = useMediaQuery(mediaSelector);
    const classes = useStyles({media});
    const getLiteral = useLiterals();
    const { showError, showSuccess } = useMessage();

    const [subscribeEmail, setSubscribeEmail] = useState<string>("");

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setSubscribeEmail(value);
    }

    const handleSubscribe = () => {
        if(!subscribeEmail || !isValidEmail(subscribeEmail)) {
            showError(getLiteral("subscribe.invalid_email"));
            return;
        }


        subscriptionService.insert(new Subscription(subscribeEmail))
            .then(() => {
                showSuccess(getLiteral("subscribe.success_subscription"));
                setSubscribeEmail("");
            })
            .catch(() => {
                showError(getLiteral("common.error.general_error"));
            });
    }
    
    return (
        <Grid className={classes.root}>
            <Typography variant="h3" className={classes.header}>
                {getLiteral("subscribe.title")}
            </Typography>

            <Typography variant="body1" className={classes.description}>
                {getLiteral("subscribe.description")}
            </Typography>

            <TextField value={subscribeEmail} variant="outlined" placeholder="some@email.com" onChange={(e) => handleChange(e)} />
            <Button variant="contained" color="primary" size="large" onClick={handleSubscribe} className={classes.button}>
                {getLiteral("subscribe.button")}
            </Button>
        </Grid>
    );
};

export { Subscribe };
