import { EventDTO } from "@djordjeandjelkovic/medgress_common_react_modules";
import { EventFilterDTO } from "$models/EventFilter";

export const getLocationInfo = (...args: string []) => {
    return args.filter(arg => arg).join(", "); // filter only if not undefined
}

export const filterEvents = (list: EventDTO[], filter: EventFilterDTO) => {
    const filteredList = new Array<EventDTO>();

    if (isEmptyList(list)) {
        return list;
    }
    
    list.forEach((event) => {
        const validEventName = filter.name && contains(event.name, filter.name);
        const validEventCity = filter.city && event.city && contains(event.city, filter.city);

        if (
            getStartOfTheDay(filter.startDate) <= event.endDate &&
            (!filter.name || validEventName) &&
            (!filter.city || validEventCity) &&
            (filter.eventType === "-1" || event.eventType.toString() === filter.eventType) &&
            (filter.natureType === "-1" || event.natureType.toString() === filter.natureType)
        ) {
            filteredList.push(event);
            return;
        }
    });

    return filteredList;
};

const isEmptyList = (list: any []) => {
    if(!list) {
        return true;
    }

    return list.length === 0;
}

const contains = (a: string, b: string): boolean => {
    return a.toLowerCase().indexOf(b.toLowerCase()) > -1;
}

const getStartOfTheDay = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}
