import React from "react";
import { useStyles } from "./styles";

type OverlayProps = {
    text: string;
    children: JSX.Element;    
};

const Overlay = (props: OverlayProps) => {
    const {text, children} = props;
    const classes = useStyles();
    return (
        <div className={classes.backdrop}>
            <h1 className={classes.preview}>{text}</h1>
            {children}
        </div>
    );
};

export { Overlay };