import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            float: "right",
        },
        icon: {
            marginRight: 10,
        },

        userProfileAvatar: {
            float: "left",
            marginRight: 5,
        },
        userProfileAvatarTitle: {
            marginLeft: theme.spacing(1),
            position: "relative",
            top: theme.spacing(1),
            width: 200,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "block",
        },
        userProfileAvatarContainer: {
            padding: 7,
        },
        fullWidth: {
            width: "100%"
        }
    })
);
