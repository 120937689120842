import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => createStyles({
    invalidResponse: {
        textAlign: "center",
        padding: 50,
    },
    companyDetailsRoot: {
        padding: 0,
        marginTop: -60,
    },
    companyDetailsContainer: {
        minHeight: 200,
        paddingBottom: 60,
    },
    companyDetailsAccordition: {
        minHeight: 120,
    },
    companyDetailsLabel: {
        marginTop: 10,
    },
    MuiAccordionActions: {
        alignItems: "normal",
    },
    MuiSvgIcon: {
        fontSize: "1.2rem",
    }
}));
