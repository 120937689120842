import React from "react";

import { useStyles } from "./styles";
import { Container, Grid, Typography, Theme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

export type PageHeaderProps = {
    title: string;
    alt?: string;
    subtitle?: string;
    imgSrc?: string;
    removeHeader?: boolean;
};

const areEqual = (prev: PageHeaderProps, next: PageHeaderProps) => {
    return prev.title === next.title && prev.subtitle === next.subtitle;
}

const PageHeader = React.memo((props: PageHeaderProps) => {
    const { title, subtitle, alt, imgSrc, removeHeader } = props;
    const classes = useStyles(props);
    const isLowerThenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    if(!(imgSrc === undefined || imgSrc === null)) {
        return (
            <img src={imgSrc} className={classes.eventBanner} alt="banner" />
        )
    }

    if(removeHeader) { //if event doesn't have cover image, remove header section from related pages.
        return null;
    }

    return (
        <Grid className={classes.root}>
            <Container>
                <Typography
                    variant="h3"
                    className={clsx(classes.title, { [classes.titleCenter]: isLowerThenSmall })}>
                    {title + (alt && !isLowerThenSmall ? " - " + alt : "")}
                </Typography>
                <Typography variant="h4" className={clsx(classes.subtitle, { [classes.titleCenter]: isLowerThenSmall })}>
                    {subtitle}
                </Typography>
            </Container>
        </Grid>
    );
}, areEqual);

export { PageHeader };
