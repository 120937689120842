import { authenticationService } from "$services/core";

export function authHeader(): any {
    // return authorization header with jwt token
    const token = authenticationService.getToken();
    if (token) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}
