import React from "react";
import { EventDetails } from "$pages/EventDetails";
import { Overlay } from "$components/Overlay";

const EventDetailsPreview = () => {
    return (
        <Overlay text="For preview purposes only">
            <EventDetails preview={true} />
        </Overlay>
    );
};

export { EventDetailsPreview };
