import React from "react";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import AddAPhoto from "@material-ui/icons/AddAPhoto";

import { useStyles } from "./styles";

import { utils } from "$helper/utils";

export interface IUploadInputProps {
    id?: string;
    name: string;
    error?: boolean;
    imgSrc: string;
    maxResolution?: number;
    renderInitials?: React.ReactElement;
    onChange: (event: { target: { name: string; value: string } }) => void;
}

const UploadInput: React.FC<IUploadInputProps> = ({
    id,
    onChange,
    imgSrc,
    renderInitials,
    maxResolution,
    name,
}) => {
    const classes = useStyles();

    let avatar: React.ReactElement = <></>;

    if (renderInitials) {
        avatar = (
            <Avatar className={classes.avatar} src={imgSrc}>
                {renderInitials}
            </Avatar>
        );
    }

    return (
        <div className={!avatar ? "" : classes.uploadContainer}>
            {avatar}

            <input
                accept="image/*"
                id={id == null ? "contained-button-file" : id}
                multiple
                type="file"
                className={classes.input}
                onChange={(e) =>
                    utils.resizeImage(
                        e.target,
                        (imgData) => onChange({ target: { name, value: imgData } }),
                        maxResolution
                    )
                }
            />

            <label htmlFor={id == null ? "contained-button-file" : id}>
                <AddAPhoto className={`${classes.addPhoto}`} />
            </label>

            <DeleteIcon
                className={`${classes.addPhoto}`}
                onClick={(e) => onChange({ target: { name, value: "" } })}
            />
        </div>
    );
};

export { UploadInput };
