import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginBottom: 20,
            marginTop: -60,
        },
        listItemContainer: {
            zIndex: 1,
            border: `1px solid ${theme.palette.secondary.light}`,
            position: "relative",
            "&:hover": {
                transform: "scale(1.1, 1.1)",
                transition: "all .2s ease-in-out",
                cursor: "pointer",
            }
        },
        paper: {
            padding: theme.spacing(2),
            margin: "auto",
            maxWidth: 500,
        },
        eventItemImage: {
            height: 246,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
        eventItemDetailsRoot: {
            padding: 15,
            backgroundColor: theme.palette.secondary.light,
        },
        eventItemDetailsContainer: {
            marginBottom: 30,
        },
        eventItemDateContainer: {
            float: "right",
        },
        eventItemDateImage: {
            marginBottom: -6,
        },
        eventItemDate: {
            padding: 2,
            color: theme.palette.text.primary,
        },
        eventItemName: {
            marginTop: 10,
            wordBreak: "break-word",
        },
        eventItemDescription: {
            height: 100,
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": 7,
            "-webkit-box-orient": "vertical",
        },
        eventItemLocation: {
            textAlign: "center",
            padding: 17,
            color: theme.palette.text.primary,
        },
        eventItemLocationIcon: {
            verticalAlign: "middle",
            paddingRight: 5,
        },
        loadMoreContainer: {
            textAlign: "center",
        },
        loadMore: {
            marginTop: 30,
            marginBottom: 30,
        },
        noEvents: {
            textAlign: "center",
            paddingTop: 150,
            paddingBottom: 150,
        },
        eventBannerLink: {
            width: "100%",
            height: "100%",
            display: "block",
        },
    })
);

export default useStyles;
