import * as React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { useStyles } from "./styles";
import { useMediaQuery } from "@material-ui/core";
import { mediaSelector } from "../Nav";

interface NavItemProps {
    to: string;
    title: string;
    onClick: (page: number) => void;
    page: number;
}

const NavItem = (props: NavItemProps) => {
    const { to, title, page, onClick } = props;
    const media = useMediaQuery(mediaSelector);
    const classes = useStyles({media});

    const handleCurrentPage = React.useCallback(() => {
        onClick(page);
    }, [page, onClick]);

    return (
        <NavLink
            to={to}
            className={clsx(classes.root, classes.link)}
            activeClassName={classes.activeMenu}
            onClick={handleCurrentPage}>
            {title}
        </NavLink>
    );
};

export { NavItem };
