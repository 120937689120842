import _ from "lodash";

export class PayForthMessage {
    code!: number;
    message!: string;

    static getMessage(code: number) {
        const message = _.find(PayForthMessage.messages, (message) => message.code === code);

        return message?.message;
    }
    static messages: PayForthMessage[] = [
        { code: 0, message: "Success." },
        { code: 1, message: "Missing parameter." },
        { code: 2, message: "Invalid parameter format." },
        { code: 3, message: "Payment option is not available for this merchant’s account." },
        { code: 4, message: "Invalid command." },
        { code: 5, message: "Invalid amount." },
        { code: 6, message: "Technical problem." },
        { code: 7, message: "Duplicate order number." },
        { code: 8, message: "Signature mismatch." },
        { code: 9, message: "Invalid merchant identifier." },
        { code: 10, message: "Invalid access code." },
        { code: 11, message: "Order not saved." },
        { code: 12, message: "Card expired." },
        { code: 13, message: "Invalid currency." },
        { code: 14, message: "Inactive payment option." },
        { code: 15, message: "Inactive merchant account." },
        { code: 16, message: "Invalid card number." },
        { code: 17, message: "Operation not allowed by the acquirer." },
        { code: 18, message: "Operation not allowed by processor." },
        { code: 19, message: "Inactive acquirer." },
        { code: 20, message: "Processor is inactive." },
        { code: 21, message: "Payment option deactivated by acquirer." },
        { code: 23, message: "Currency not accepted by acquirer." },
        { code: 24, message: "Currency not accepted by processor." },
        { code: 25, message: "Processor integration settings are missing." },
        { code: 26, message: "Acquirer integration settings are missing." },
        { code: 27, message: "Invalid extra parameters." },
        { code: 29, message: "Insufficient funds." },
        { code: 30, message: "Authentication failed." },
        { code: 31, message: "Invalid issuer." },
        { code: 32, message: "Invalid parameter length." },
        { code: 33, message: "Parameter value not allowed." },
        { code: 34, message: "Operation not allowed." },
        { code: 35, message: "Order created successfully." },
        { code: 36, message: "Order not found." },
        { code: 37, message: "Missing return URL." },
        { code: 38, message: "Token service inactive." },
        { code: 39, message: "No active payment option found." },
        { code: 40, message: "Invalid transaction source." },
        { code: 42, message: "Operation amount exceeds the authorized amount." },
        { code: 43, message: "Inactive Operation." },
        { code: 44, message: "Token name does not exist." },
        { code: 46, message: "Channel is not configured for the selected payment option." },
        { code: 47, message: "Order already processed." },
        { code: 48, message: "Operation amount exceeds captured amount." },
        { code: 49, message: "Operation not valid for this payment option." },
        { code: 50, message: "Merchant per transaction limit exceeded." },
        { code: 51, message: "Technical error." },
        { code: 52, message: "Consumer is not in OLP database." },
        { code: 53, message: "Merchant is not found in OLP Engine DB." },
        { code: 54, message: "Transaction cannot be processed at this moment." },
        { code: 55, message: "OLP ID Alias is not valid. Please contact your bank." },
        { code: 56, message: "OLP ID Alias does not exist. Please enter a valid OLP ID Alias." },
        { code: 57, message: "Transaction amount exceeds the daily transaction limit." },
        { code: 58, message: "Transaction amount exceeds the per transaction limit." },
        { code: 59, message: "Merchant Name and SADAD Merchant ID do not match." },
        { code: 60, message: "The entered OLP password is incorrect. Please provide a valid password." },
        { code: 62, message: "Token has been created." },
        { code: 63, message: "Token has been updated." },
        { code: 64, message: "3-D Secure check requested." },
        { code: 65, message: "Transaction waiting for customer’s action." },
        { code: 66, message: "Merchant reference already exists." },
        { code: 67, message: "Dynamic Descriptor not configured for selected payment option." },
        { code: 68, message: "SDK service is inactive." },
        { code: 69, message: "Mapping not found for the given error code." },
        { code: 70, message: "device_id mismatch." },
        { code: 71, message: "Failed to initiate connection." },
        { code: 72, message: "Transaction has been cancelled by the consumer." },
        { code: 73, message: "Invalid request format." },
        { code: 74, message: "Transaction failed." },
        { code: 75, message: "Transaction failed." },
        { code: 76, message: "Transaction not found in OLP." },
        { code: 77, message: "Error transaction code not found." },
        { code: 78, message: "Failed to check fraud screen." },
        { code: 79, message: "Transaction challenged by fraud rules." },
        { code: 80, message: "Invalid payment option." },
        { code: 82, message: "Inactive fraud service." },
        { code: 83, message: "Unexpected user behavior." },
        {
            code: 84,
            message:
                "Transaction amount is either bigger than maximum or less than minimum amount accepted for the selected plan.",
        },
        { code: 86, message: "Installment plan is not configured for Merchant account." },
        { code: 87, message: "Card BIN does not match accepted issuer bank." },
        { code: 88, message: "Token name was not created for this transaction." },
        { code: 89, message: "Failed to retrieve digital wallet details." },
        { code: 90, message: "Transaction in review." },
        { code: 92, message: "Invalid issuer code." },
        { code: 93, message: "service inactive." },
        { code: 94, message: "Invalid Plan Code." },
        { code: 95, message: "Inactive Issuer." },
        { code: 96, message: "Inactive Plan." },
        { code: 97, message: "Operation not allowed for service." },
        { code: 98, message: "Invalid or expired call_id." },
        { code: 99, message: "Failed to execute service." },
        { code: 100, message: "Invalid expiry date." },
        { code: 101, message: "Bill number not found." },
        { code: 102, message: "Apple Pay order has been expired." },
        { code: 103, message: "Duplicate subscription ID." },
        { code: 104, message: "No plans valid for request." },
        { code: 105, message: "Invalid bank code." },
        { code: 106, message: "Inactive bank." },
        { code: 107, message: "Invalid transfer_date." },
        { code: 110, message: "Contradicting parameters, please refer to the integration guide." },
        { code: 111, message: "Service not applicable for payment option." },
        { code: 112, message: "Service not applicable for payment operation." },
        { code: 113, message: "Service not applicable for e-commerce indicator." },
        { code: 114, message: "Token already exist." },
        { code: 115, message: "Expired invoice payment link." },
        { code: 116, message: "Inactive notification type." },
        { code: 117, message: "Invoice payment link already processed." },
        { code: 118, message: "Order bounced." },
        { code: 119, message: "Request dropped." },
        { code: 120, message: "Payment link terms and conditions not found." },
        { code: 121, message: "Card number is not verified." },
        { code: 122, message: "Invalid date interval." },
        { code: 123, message: "You have exceeded the maximum number of attempts." },
        { code: 124, message: "Account successfully created." },
        { code: 125, message: "Invoice already paid." },
        { code: 126, message: "Duplicate invoice ID." },
        { code: 127, message: "Merchant reference is not generated yet." },
        { code: 128, message: "The generated report is still pending, you can’t download it now." },
        { code: 129, message: "“Downloaded report” queue is full. Wait till its empty again." },
        { code: 134, message: "Your search results have exceeded the maximum number of records." },
        { code: 136, message: "The Batch file validation is failed." },
        { code: 137, message: "Invalid Batch file execution date." },
        { code: 138, message: "The Batch file still under validation." },
        { code: 140, message: "The Batch file still under processing." },
        { code: 141, message: "The Batch reference does not exist." },
        { code: 142, message: "The Batch file header is invalid." },
        { code: 144, message: "Invalid Batch file." },
        { code: 146, message: "The Batch reference is already exist." },
        { code: 147, message: "The Batch process request has been received." },
        { code: 148, message: "Batch file will be processed." },
        { code: 149, message: "Payment link request id not found." },
        { code: 150, message: "Payment link is already open." },
        { code: 151, message: "3ds_id does not exist." },
        { code: 152, message: "3Ds verification doesn’t match the request details." },
        { code: 154, message: "You have reached the maximum number of upload retries." },
        { code: 155, message: "The upload retries is not configured." },
        { code: 662, message: "Operation not allowed. The specified order is not confirmed yet." },
        { code: 666, message: "Transaction declined." },
        { code: 773, message: "Transaction closed." },
        { code: 777, message: "The transaction has been processed, but failed to receive confirmation." },
        { code: 778, message: "Session timed-out." },
        { code: 779, message: "Transformation error." },
        { code: 780, message: "Transaction number transformation error." },
        { code: 781, message: "Message or response code transformation error." },
        { code: 783, message: "Installments service inactive." },
        { code: 784, message: "Transaction still processing you can’t make another transaction." },
        { code: 785, message: "Transaction blocked by fraud check." },
        { code: 787, message: "Failed to authenticate the user." },
        { code: 788, message: "Invalid bill number." },
        { code: 789, message: "Expired bill number." },
        { code: 790, message: "Invalid bill type code." },
    ];
}
