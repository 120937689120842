import React from "react";
import { EmailField, SpinnerButton } from "@djordjeandjelkovic/medgress_common_react_modules";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useStyles } from "../styles";
import { useForgotPassword } from "./useForgotPassword";
import { useLiterals } from "$hooks/Translate/useLiterals";

export type ForgotPasswordProps = {
    open: boolean;
    onClose: () => void;
};
export const ForgotPassword = (props: ForgotPasswordProps) => {
    const { open, onClose } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();
    const { email, onChange, onResetClick } = useForgotPassword(props);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{getLiteral("sign-up.forgot_password.lnk_forgot_password")}</DialogTitle>
            <DialogContent>
                <EmailField
                    name="email"
                    label="email"
                    value={email.value}
                    error={email.error}
                    onChange={onChange}
                    TextFieldProps={{
                        className: classes.fpEmailField,
                        variant: "outlined",
                    }}
                />
                <SpinnerButton
                    ButtonProps={{
                        variant: "contained",
                        color: "primary",
                        className: classes.fpResetButton,
                    }}
                    isLoading={email.isLoading}
                    title={getLiteral("sign-up.forgot_password.btn_reset")}
                    onClick={onResetClick}
                />
            </DialogContent>
        </Dialog>
    );
};
