import React, { useState } from "react";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { TicketCount } from "./TicketCount";
import {
    DisplayTicketLimitEnum,
    EventChange,
    TicketDTO,
} from "@djordjeandjelkovic/medgress_common_react_modules";
import { useStyles } from "./styles";
import Grid from "@material-ui/core/Grid";
import { AvailableTickets } from "./AvailableTickets";
import AddToCartButton from "./AddToCartButton";
import { TicketsCount } from "./useTicketsCount";
import { TicketGrid } from "./TicketGrid";

type TicketProps = {
    showAvailableTickets: number;
    onTicketCountChange: (event: EventChange<string>, ticketId: string) => void;
    onAddToCart: (ticketId: string) => void;
    disabled: boolean;
    hidden: boolean;
    ticketsCount: TicketsCount;
    isLoading: boolean;
    embeded?: boolean;
    acronym: string;
    tickets: TicketDTO[];
};

export const Ticket = (props: TicketProps) => {
    const {
        disabled,
        ticketsCount,
        showAvailableTickets,
        onTicketCountChange,
        onAddToCart,
        hidden,
        isLoading,
        embeded,
        acronym,
        tickets,
    } = props;
    const classes = useStyles(props);

    const [ticket, setTicket] = useState<TicketDTO>(tickets[0]);

    const handleChangeTicket = ({ target: { name, value } }: EventChange<string>) => {
        onTicketCountChange({ target: { name: ticket.id, value: "0" } }, ticket.id);
        const selectedTicket = tickets.find((t) => t.id === value);
        if (!selectedTicket) {
            return;
        }

        setTicket(selectedTicket);
    };

    return (
        <Grid container>
            <Grid item sm={3}>
                <ReceiptIcon style={{ fontSize: 64 }} className={classes.ticketIcon} />
            </Grid>
            <Grid item sm={9} container>
                <TicketGrid
                    hidden={hidden}
                    tickets={tickets}
                    ticket={ticket}
                    onTicketChange={handleChangeTicket}></TicketGrid>
                {!hidden && (
                    <>
                        <Grid item container xs={12}>
                            <Grid item xs={6}>
                                <TicketCount
                                    disabled={isLoading}
                                    ticketId={ticket.id}
                                    hidden={hidden}
                                    count={
                                        ticketsCount.find((ticketItem) => ticketItem.ticket.id === ticket.id)
                                            ?.count || 0
                                    }
                                    onChange={onTicketCountChange}
                                    name={ticket.id}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AddToCartButton
                                    onAddToCart={onAddToCart}
                                    disabled={disabled || !ticketsCount.hasSelectedTicket(ticket.id)}
                                    hidden={hidden}
                                    isLoading={isLoading}
                                    embeded={Number(embeded)}
                                    id={ticket.id}
                                    acronym={acronym}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AvailableTickets
                                total={ticket.limit}
                                sold={ticket.soldTickets}
                                showAvailableTickets={showAvailableTickets || isHidden(hidden)}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

const isHidden = (hidden: boolean) => {
    return hidden ? DisplayTicketLimitEnum.DONT_SHOW : DisplayTicketLimitEnum.SHOW;
};



