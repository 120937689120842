import { Theme, createStyles, makeStyles } from "@material-ui/core";
import images from "$assets/images";

type StyleProps = {
    mediaSmDown: boolean;
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterContainer: {
            height: 485,
            backgroundImage: `url(${images.eventsCover})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
        },
        filterContainerShade: {
            height: "100%",
            opacity: 0.85,
        },
        filterContent: {
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            width: "75%",
        },
        filterItemsContainer: (props: StyleProps) => ({
            paddingTop: !props.mediaSmDown ? 10 : 5,
            paddingBottom: !props.mediaSmDown ? 10 : 5,
            paddingLeft: !props.mediaSmDown ? "5vw" : 5,
            paddingRight: !props.mediaSmDown ? "5vw" : 5,
            background: theme.palette.background.default,
            marginBottom: 10,
        }),
        filterItem: (props: StyleProps) => ({
            color: theme.palette.text.primary,
            width: "100%",
            padding: !props.mediaSmDown ? 2 : undefined,
            marginTop: !props.mediaSmDown ? undefined : 6,
        }),
        filterHeader: {
            marginTop: 80,
            color: theme.palette.primary.contrastText,
        },
        filterHeaderEvents: {
            color: theme.palette.primary.main,
        },
        filterValuesContainer: {
            position: "relative",
            top: 6,
        },
        clearContainer: {
            textAlign: "left",
            padding: 2,
        },

        collapseFullWidth: {
            width: "100%",
        },
        collapseWrapperInner: {
            display: "flex",
        },
        upcomingEvents: {
            textAlign: "center",
        },
    })
);
