import * as React from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { useMessage } from "$helper/useMessage";

const usePayFortResponse = () => {
    const history = useHistory();
    const { showError } = useMessage();

    const query = React.useMemo(() => qs.parse(history.location.search), [history.location.search]);

    if (query.response_code && (query.response_code as string) !== "14000")
        showError(query.response_message as string);

    const response = React.useMemo(
        () => ({
            hasResponse: typeof query.response_code !== "undefined",
            isSuccess: (query.response_code as string) === "14000",
            message: query.response_message as string,
        }),
        [query]
    );

    return response;
};

export { usePayFortResponse };
