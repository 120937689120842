import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loginWrapper: {
            paddingTop: 20,
            paddingBottom: 20,
            margin: "20px auto",
            maxWidth: 500,
        },
        title: {
            textAlign: "center",
        },
        progress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        error: {
            color: theme.palette.error.main,
        },
    })
);
