import React, { ChangeEvent, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { useLiterals } from "$hooks/Translate/useLiterals";
import { useStyles } from "./styles";
import { isValidEmail, ContactUs as ContactUsForm } from "@djordjeandjelkovic/medgress_common_react_modules";
import { contactUsService } from "$services/contactus.service";
import { useMessage } from "$helper/useMessage";

type ContactUsProps = {
    eventId: string;
    preview?: boolean;
};

export const ContactUs = (props: ContactUsProps) => {
    const { eventId, preview } = props;
    const getLiteral = useLiterals();
    const classes = useStyles();
    const { showError, showSuccess } = useMessage();

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    
    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        const name = event.target.name;

        switch(name) {
            case "name":
                setName(value.substr(0, 100));
                break;
            case "email":
                setEmail(value.substr(0, 100));
                break;
            case "message":
                setMessage(value.substr(0, 1000));
                break;
            default:
                console.error("Invalid field", name);
        }
    }

    const handleSubmit = () => {
        if(name.length < 5 || name.length > 100) {
            showError(getLiteral("contact_us.error.invalid_name"));
            return;
        }

        if(email.length < 1 || email.length > 100 || !isValidEmail(email)) {
            showError(getLiteral("contact_us.error.invalid_email"));
            return;
        }

        if(message.length < 5 || message.length > 1000) {
            showError(getLiteral("contact_us.error.invalid_message"));
            return;
        }

        contactUsService.send(eventId, new ContactUsForm(name, email, message))
        .then(() => {
            showSuccess(getLiteral("contact_us.form.success_submit"));

            setName("");
            setEmail("");
            setMessage("");
        }).catch(() => {
            showError(getLiteral("common.error.general_error"));
        });        
    }

    return (
        <>
            <TextField
                label={getLiteral("contact_us.form.name")}
                placeholder={getLiteral("contact_us.form.name.placeholder")}
                fullWidth
                name="name"
                value={name}
                variant="outlined"
                className={classes.input}
                disabled={preview}
                onChange={(e) => handleChange(e)}
            />

            <TextField
                label={getLiteral("contact_us.form.email")}
                placeholder={getLiteral("contact_us.form.email.placeholder")}
                fullWidth
                name="email"
                value={email}
                variant="outlined"
                className={classes.input}
                disabled={preview}
                onChange={(e) => handleChange(e)}
            />

            <TextField
                label={getLiteral("contact_us.form.message")}
                placeholder={getLiteral("contact_us.form.message.placeholder")}
                fullWidth
                name="message"
                value={message}
                multiline
                rows={6}
                variant="outlined"
                className={classes.input}
                disabled={preview}
                onChange={(e) => handleChange(e)}
            />

            <Button disabled={preview} onClick={() => handleSubmit()} variant="contained" color="primary" fullWidth className={classes.input} size="large">
                {getLiteral("contact_us.form.button.send")}
            </Button>
        </>
    );
};
