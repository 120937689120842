import React from "react";
import { Redirect } from "react-router-dom";

import _map from "lodash/map";
import _first from "lodash/first";
import _keys from "lodash/keys";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import clsx from "clsx";

import { useTotalInformation } from "./useTotalInformation";

import { useTicketsUtils } from "$helper/useTicketsUtils";
import { DECIMAL_PLACES } from "$constants/Numbers";

import { TicketCart } from "$models/types/TicketCart";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as PaymentActionCreators from "$redux/actions/Payment/paymentActions";
import { usePaymentSelectors } from "$redux/selectors/usePaymentSelectors";

import { useStyles } from "../styles";

type TotalInformationProps = {
    eventId: string;
    cartId: string;
};

const TotalInformation = ({ cartId, eventId }: TotalInformationProps) => {
    const classes = useStyles();
    const { tickets, total, currency, isLoading, isFree, isSuccess, onFinish } = useTotalInformation(
        cartId,
        eventId
    );

    const { showTotalSum } = useTicketsUtils();

    const dispatch = useDispatch();
    const { setTerms } = bindActionCreators(PaymentActionCreators, dispatch);
    const { payment } = usePaymentSelectors();

    if (!tickets) return null;

    if (isSuccess) {
        return <Redirect to={{ pathname: "/success-payment", state: { eventId } }} />;
    }

    return (
        <>
            <Typography variant="h4">Order Summary</Typography>

            {_map(_keys(tickets), (key, idx) => {
                const ticket: TicketCart | undefined = _first(tickets[key]);
                return (
                    <Grid
                        key={key}
                        item
                        xs={12}
                        className={clsx(classes.cardInfo, {
                            [classes.cardBorder]: idx !== _keys(tickets).length - 1,
                        })}>
                        {ticket?.ticket.title} x{tickets[key].length}
                        <span className={classes.ticketAmount}>
                            {showTotalSum(ticket?.ticket.price?.amount.toFixed(DECIMAL_PLACES), currency)}
                        </span>
                    </Grid>
                );
            })}
            <Grid item xs={12} className={classes.cardTotal}>
                Total: {showTotalSum(total.toFixed(DECIMAL_PLACES), currency)}
            </Grid>

            <Typography className={classes.termsTitle} variant="h5">
                Terms and conditions
            </Typography>
            <Typography>
                - Tickets are non-refundable, non-changeable and non-transferable. Customer must show a valid
                personal identification document along with the booking confirmation voucher to avail the
                service.
            </Typography>
            <Typography>
                - Online ticket resale is prohibited. If the ticket is obtained from and through any means
                other than the authorized channel, such ticket will be void and redemption will be declined.
                It is the responsibility of the customer to comply with the respective Ticket Type Terms and
                Conditions.
            </Typography>
            <Typography>
                - If tickets are to be collected onsite, the customer or a person authorized by them in
                writing may collect the tickets and only upon presentation of a booking confirmation voucher
                and an identification document.
            </Typography>
            <Typography>
                - It is the responsibility of the customer to be present at the event venue on time. Failure
                to attend or miss out on the activity/experience at the chosen date of booking will not be
                refunded by Entryvent.
            </Typography>

            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        value={payment.terms}
                        onChange={(e) => setTerms(e.target.checked)}
                    />
                }
                label="I accept terms &amp; conditions"
            />

            {isFree && (
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!payment.terms || isLoading}
                    size="large"
                    onClick={() => {
                        onFinish();
                    }}>
                    {isLoading && <CircularProgress className={classes.paymentProgress} size={24} />}
                    Finish
                </Button>
            )}
        </>
    );
};

export default TotalInformation;
