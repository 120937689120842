export const SET_CART_TIMEOUT = "SET_CART_TIMEOUT" as "SET_CART_TIMEOUT";
export const DELETE_TIMEOUT = "DELETE_TIMEOUT" as "DELETE_TIMEOUT";
export const INIT = "INIT" as "INIT";
export const SET_CART_ID_INIT = "SET_CART_ID_INIT" as "SET_CART_ID_INIT";

export type CartTimeoutAction =
    | {
          type: typeof SET_CART_TIMEOUT;
      }
    | {
          type: typeof DELETE_TIMEOUT;
          eventId: string;
      }
    | {
          type: typeof INIT;
          eventId: string;
      } 
    | {
          type: typeof SET_CART_ID_INIT;
          eventId: string;
          cartId: string;
    };
