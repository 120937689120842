import React from "react";

import { Grid, Typography } from "@material-ui/core";

import * as BuyerActionCreators from "$redux/actions/Buyer/buyerActions";

import {
    TextField,
    EmailField,
    EventChange,
    ErrorType,
} from "@djordjeandjelkovic/medgress_common_react_modules";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { styles } from "./styles";

//types
import { ReduxStore } from "$redux/reducers";
import { IBuyer } from "$models/Checkout";

const Buyer = () => {
    const dispatch = useDispatch();
    const buyer = useSelector<ReduxStore, ErrorType<IBuyer> | undefined>((store) => store.checkout.buyerInfo);
    const { setName, setEmail } = bindActionCreators(BuyerActionCreators, dispatch);
    const classes = styles();

    const handleChange = (event: EventChange<string>) => {
        switch (event.target.name) {
            case "name":
                setName(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
        }
    };

    return (
        <Grid container spacing={1} className={classes.wrapper}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="name"
                    label="Name"
                    required={true}
                    error={buyer?.name.error}
                    value={buyer?.name.value || ""}
                    onChange={handleChange}
                    TextFieldProps={{ variant: "outlined" }}
                />
            </Grid>
            <Grid item xs={12}>
                <EmailField
                    fullWidth
                    name="email"
                    label="Email"
                    required={true}
                    error={buyer?.email.error}
                    value={buyer?.email.value || ""}
                    onChange={handleChange}
                    TextFieldProps={{ variant: "outlined" }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.typography}>
                    Your ticket will be delivered on this email
                </Typography>
            </Grid>
        </Grid>
    );
};

export { Buyer };
