import React from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { IStep } from "../IStep";
import { StepIcon } from "../StepIcon";

type AttendeeStepperProps = {
    activeStep: IStep;
    stepperLabels: { label: string; icon: React.ComponentType<any>; index: number; id: string }[];
};

export const AttendeeStepper = (props: AttendeeStepperProps) => {
    const { activeStep, stepperLabels } = props;

    const stepTypes = [...new Set(stepperLabels.map((step) => step.label))];

    const getCurrentStepLabel = (currentStep: string) => {
        if(currentStep === "Payment") {
            return `Payment`;
        }

        const countOfTickets = stepperLabels.filter((step) => step.label === currentStep).length;
        const firstIndexOfCurrentStep = stepperLabels.findIndex((step) => step.label === currentStep);

        if(activeStep.index! > firstIndexOfCurrentStep) {
            if(activeStep.index! > firstIndexOfCurrentStep + countOfTickets) {
                return `${countOfTickets} of ${countOfTickets}`;
            }

            return `${(activeStep.index! - firstIndexOfCurrentStep)} of ${countOfTickets}`;
        }

        return `0 of ${countOfTickets}`;
    }

    const getStepIcon = (currentStep: string): React.ComponentType<any> | undefined => {
        return stepperLabels.find((step) => step.label === currentStep)?.icon;
    }

    const getActiveStepIndex = () => {
        const currentLabel = stepperLabels.find((step) => step.index === activeStep.index!)?.label;
        return stepTypes.findIndex((step) => step === currentLabel);
    }

    return (
        <Stepper activeStep={getActiveStepIndex()} alternativeLabel>
            {stepTypes.map((step, index) => (
                <Step key={`step-${index}`}>
                    <StepLabel StepIconComponent={(props) => <StepIcon {...props} stepIcon={getStepIcon(step)} />}>
                        {`${getCurrentStepLabel(step)} ${step}`}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};
