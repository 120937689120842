import React from "react";

type HideElementProps = {
    inCase: boolean;
    children: React.ReactNode;
}

const HideElement = (props: HideElementProps) => {
    const { inCase, children } = props;

    if(inCase) {
        return null;
    }

    return (
        <React.Fragment>
            { children }
        </React.Fragment>
    );
}

export default HideElement;
