import React from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Typography } from "@material-ui/core";
import { EventDetailsLoader, PageHeader,PopularEvents } from "$components/core";
import { Tickets } from "./Tickets";
import { EventInformation } from "./EventInformation";
import { EventInformationDetails } from "./EventInformationDetails";
import { FindUs } from "./FindUs";
import { useEventDetailsState } from "./useEventDetailsState";
import { useStyles } from "./styles";
import { EventStatusEnum } from "@djordjeandjelkovic/medgress_common_react_modules";
import { useLiterals } from "$hooks/Translate/useLiterals";

type EventDetailsProps = {
    preview?: boolean;
}

const EventDetails = (props: EventDetailsProps) => {
    const { preview } = props;
    const { acronym } = useParams<{ acronym: string }>();
    const { event, eventQRCode, location } = useEventDetailsState({ acronym });
    const classes = useStyles();
    const getLiteral = useLiterals();

    if (!event) {
        return (
            <Container>
                <EventDetailsLoader />
            </Container>
        );
    }

    if (!(event.status === EventStatusEnum.PUBLISHED || preview)) {
        return (
            <Typography variant="h4" className={classes.noInformation}>
                {getLiteral("event_details.not_published_event")}
            </Typography>
        );
    }

    return (
        <>
            <PageHeader
                title={event.coverImage ? "" : event.name}
                subtitle={event.coverImage ? "" : event.acronym}
                imgSrc={event.coverImage}
                removeHeader={true}
            />
            <Container className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                        <EventInformation details={event} eventQRCode={eventQRCode} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Tickets acronym={acronym} event={event} preview={preview} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <EventInformationDetails eventId={event.id} preview={preview} />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <FindUs
                            latitude={location.latitude}
                            longitude={location.longitude}
                            isMarkerShown={location.isMarkerShown || false}
                        />
                    </Grid>
                </Grid>
            </Container>
            <PopularEvents />
        </>
    );
};

export { EventDetails };
