import { useMemo } from "react";
import { useCheckoutSelectors, useTicketCartSelector } from "$redux/selectors";
import { AttendeeWithRedux } from "$components/core";
import { IStep } from "./IStep";
import {
    ATTENDEE_INDEX_BACKOFFSET,
    ATTENDEE_INDEX_OFFSET,
    createStepperLabels,
    extractActiveStepIndex,
    findAttendee,
    getActiveStepFromLocalStorage,
    setActiveStepToLocalStorage,
} from "./checkoutHelper";
import { useCheckoutActionCreators } from "./useCheckoutActionsCreators";

type ActiveStepProps = {
    eventId: string;
};
export const useActiveStep = ({ eventId }: ActiveStepProps) => {
    const { activeStep } = useCheckoutSelectors(eventId);
    const { ticketsCart } = useTicketCartSelector(eventId);
    const { tickets } = useCheckoutSelectors(eventId!);
    const { setActiveStep } = useCheckoutActionCreators();

    const stepperOptions: IStep[] = useMemo(
        () => createStepperLabels(tickets!, eventId!),
        [tickets, eventId]
    );

    const setCurrentStep = (previous: any) => {
        const currentStep = getActiveStepFromLocalStorage();
        const index = extractActiveStepIndex(currentStep);

        const isSameEvent = !previous || previous.eventId === eventId;
        const isIndexInRange = (i: number) => {
            return i >= 0 && i < stepperOptions.length;
        };
        const attendeeHasId = (i: number) => {
            return findAttendee(ticketsCart, i, eventId).attendee?.id.value !== "";
        };

        if (isSameEvent && isIndexInRange(index - 1)) {
            if (isIndexInRange(index) && attendeeHasId(index - 1)) {
                setActiveStepAndSave(index);
            } else {
                setActiveStepAndSave(index - 1);
            }
            return;
        }

        setActiveStepAndSave(0);
    };

    const setActiveStepAndSave = (index: number) => {
        setActiveStepToLocalStorage(stepperOptions[index]);
        setActiveStep(stepperOptions[index]);
    };

    return {
        activeStep,
        isAttendeeStep: activeStep.component === AttendeeWithRedux,
        setCurrentStep,
        stepperOptions,
        hasAttendeeSteps: stepperOptions.length > ATTENDEE_INDEX_OFFSET + ATTENDEE_INDEX_BACKOFFSET,
    };
};
