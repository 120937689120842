import React from "react";

import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

import { IAttendee, ErrorType } from "@djordjeandjelkovic/medgress_common_react_modules";
import { Attendee } from "$components/core";

import { useLiterals } from "$hooks/Translate/useLiterals";
import TicketFooterActions from "./TicketFooterActions/TicketFooterActions";

import { useStyles } from "./styles";

type CollapseAttendeeProps = {
    in: boolean;
    attendee: ErrorType<IAttendee>;
    cartId: string;
    onView: React.MouseEventHandler<HTMLButtonElement>;
    eventId: string;
    showTicketActions: boolean;
};

const CollapseAttendee = (props: CollapseAttendeeProps) => {
    const { in: inCollapse, attendee, cartId, onView, eventId, showTicketActions } = props;
    const classes = useStyles();
    const getLiteral = useLiterals();

    if (attendee.id.value.startsWith("deleted")) {
        return (
            <Collapse in={inCollapse} unmountOnExit>
                <CardContent className={classes.cardContent}>
                    <Typography variant="body2">
                        {getLiteral("history_purchase_details.attendee_does_not_exist")}
                    </Typography>
                </CardContent>
            </Collapse>
        );
    }
    
    return (
        <Collapse in={inCollapse} unmountOnExit>
            <CardContent className={classes.cardContent}>
                <Attendee attendee={attendee} attendeeType={Number(attendee.attendeeType?.value)} readOnly disabled />
                <TicketFooterActions
                    attendeeId={attendee.id.value}
                    cartId={cartId}
                    onView={onView}
                    eventId={eventId}
                    showTicketActions={showTicketActions}
                />
            </CardContent>
        </Collapse>
    );
};

export default React.memo(
    CollapseAttendee,
    (prevProps, nextProps) => prevProps.in === nextProps.in && prevProps.attendee === nextProps.attendee
);
